import { Box, Button, FormControl, FormControlLabel, Tab, Tabs } from '@mui/material'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'
import { setImageProperty } from '../../../../../../../redux/reducers/uploadSessionReducer'
import { PublicationImageCard } from '../../../../../../shared-components/card/PublicationImageCard'
import { CustomCheckbox } from '../../../../../../shared-components/checkbox/CustomCheckbox'
import { ConfirmDialog } from '../../../../../../shared-components/dialogs/ConfirmDialog'
import { CustomTextarea } from '../../../../../../shared-components/textfield/CustomTextArea'
import styles from './Step3.module.scss'
import {
  MAXIMUM_CHARACTERS_DESCRIPTION,
  MINIMUM_CHARACTERS_DESCRIPTION, VALID
} from '../../../../../../../utils/constants'
import { theme } from '../../../../../../../utils/theme'
import { InputChip } from '../../../../../../shared-components/inputChip'
import SavedImages from '../../../../../../shared-components/step3-components/savedImages'
import { keywordsDefaultStep3 } from '../../../../../../../utils/helpers'

type Props = {
  saveStep?: () => void;
  onDeleteAllImages?: () => void;
  handleDeleteImage?: (key?: string| number) =>void
}
type imageAddedTypes = {
  id: number,
  checked: boolean
}

interface IDialogProps { open: boolean; actionType: 'save' | 'delete'};

function CustomTabPanel(props: any) {
  const { children, value, indexData, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== indexData}
      id={`simple-tabpanel-${indexData}`}
      aria-labelledby={`simple-tab-${indexData}`}
      {...other}
    >
      {value === indexData && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </Box>
  );
}
const validateSaveAll = (addedToSelectionElements: any, uploadSession: any) => {
  return addedToSelectionElements.some((el: any) => {
    const image = uploadSession?.images.find((img: any) => img.imageId === el.id);
    if (!image?.keywords?.length || !image?.description) {
      return true;
    }
    if (image?.description.length < MINIMUM_CHARACTERS_DESCRIPTION || image?.keywords?.length < 7) {
      return true;
    }
    return false;
  })
}

export const Step3 = ({ saveStep, onDeleteAllImages, handleDeleteImage }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [valueTab, setValueTab] = useState(0);
  const profile = useSelector(
    (state: RootState) => state.user.usersData
  )
  const [chips, setChips] = useState<string[]>([]);
  const [descriptionAll, setDescriptionAll] = useState('')
  const [keywordsAll, setKeywordsAll] = useState<string[]>([])
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<IDialogProps>({ open: false, actionType: 'delete' });
  const [addedToSelectionElements, setAddedToSelectionElements] = useState<imageAddedTypes[]>([])
  const [individualCheckboxStates, setIndividualCheckboxStates] = useState<
    boolean[]
  >([])
  const uploadSession = useSelector(
    (state: RootState) => state.uploadSession.uploadSession
  )

  useEffect(() => {
    if (uploadSession?.images && !uploadSession.images.length) {
      setValueTab(1);
    }
  }, [uploadSession])

  const handleDeleteAll = () => {
    if(onDeleteAllImages) {
      onDeleteAllImages()
    }
    handleOpen('delete')
  }
  const handleOpen = (actionType: 'save' | 'delete') => {
    setOpenConfirmationDialog({
      open: !openConfirmationDialog.open,
      actionType
    })
  }
  const handleSelectAllChange = (event: React.ChangeEvent<any>) => {
    const isChecked = event.target.checked

    if (isChecked) {
      const allImageIds =
        uploadSession?.images.map((image) => image.imageId) || []
      // setAddedToSelectionElements(allImageIds)
      const updateArray = addedToSelectionElements.map((element) => {
        return {
          ...element,
          checked: true
        }
      })
      setAddedToSelectionElements([...updateArray])
    } else {
      const updateArray = addedToSelectionElements.map((element) => {
        return {
          ...element,
          checked: false
        }
      })
      setAddedToSelectionElements([...updateArray])
      setIndividualCheckboxStates([])
    }
  }
  const handleSaveAll = async () =>{
    if(typeof saveStep !== 'undefined'){
      try {
         const response = await saveStep() as any;

        if(response?.status === 'success') {
          setAddedToSelectionElements([]);
          setIndividualCheckboxStates([])
          setKeywordsAll([])
          setDescriptionAll('')
        }
      } catch (error) {
        console.error(error);
        // Handle error
      } finally {
        setOpenConfirmationDialog({ open: false, actionType: 'delete' });
      }
    }
  }
  const getAllDefaultKeywords = (uploadSession: any) => {
    if (!uploadSession?.images) return [];

    return uploadSession.images.reduce((acc: string[], image: any) => {
      if (image.defaultKeywords) {
        acc.push(...image.defaultKeywords);
      }
      return acc;
    }, []);
  };
  const handleIndividualCheckboxChange =
    (el: number) => (event: React.ChangeEvent<any>) => {
      const isChecked = event.target.checked
      const newUpdate = addedToSelectionElements.map((element) => {
        if(element?.id === el) {
          return {
            ...element,
            checked: isChecked
          }
        }
        else {
          return  {
            ...element
          }
        }
      })
      setAddedToSelectionElements([...newUpdate])
    }
  const handleAddtoSelection = (element: number) => {
    const imgIndex = addedToSelectionElements.findIndex((el: imageAddedTypes) => el.id === element);
    if (imgIndex === -1) {
      const findImage = uploadSession?.images.find((img) => img.imageId === element);
        const defaultKeywords = keywordsDefaultStep3(findImage) as string[];
        dispatch(
          setImageProperty({
            imageId: findImage?.imageId!,
            propertyName: 'defaultKeywords',
            value: defaultKeywords,
          })
        );
      setAddedToSelectionElements((prev) => [...prev, {id: element, checked: false}])
    } else {
      setAddedToSelectionElements((prev) => {
        return prev.filter((itm) => itm.id !== element);
      });
    }
  }
  const handleDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.split('/\n/')[0].replaceAll('\n', ' ');
    addedToSelectionElements.forEach((item) => {
      const image = uploadSession?.images.find((img) => img.imageId === item.id);
      if (item.checked) {
        dispatch(
          setImageProperty({
            imageId: image?.imageId!,
            propertyName: 'description',
            value: newValue,
          })
        )
      }
    })
    setDescriptionAll(newValue)
  }
  const handleKeywordsChange = (keywordsChips: string[]) => {
    setKeywordsAll(keywordsChips)
    addedToSelectionElements.forEach((item) => {
      const image = uploadSession?.images.find((img) => img.imageId === item.id);
      if (item.checked) {
        dispatch(
          setImageProperty({
            imageId: image?.imageId!,
            propertyName: 'keywords',
            value: keywordsChips,
          })
        )
      }
    })
  }
  return (
    <>
      <ConfirmDialog
        open={openConfirmationDialog.open}
        handleOpen={() => handleOpen(openConfirmationDialog.actionType) }
        handleConfirm={() => openConfirmationDialog.actionType === 'delete' ? handleDeleteAll() : handleSaveAll()}
        dialogMessage={
          openConfirmationDialog.actionType === 'delete'
            ? t('Are you sure to delete all of the images')
            : t('Are you sure to save all of the images')
        }
        dialogTitle={
          openConfirmationDialog.actionType === 'delete'
            ? t('Delete All Images')
            : t('Save All Images')
        }
      />
      <div>
        <div className={styles.titleContainer}>
          <span className={styles.step}>{t('Step', { step: 3 })}</span>
          <span className={styles.title}>{t('Description and Keywords')}</span>
        </div>
        <div className={styles.description}>{t('Step3_description')}</div>

        <div className={styles.topElementsContainer}>
          {uploadSession?.images.map((element, index) => {
            const isSelected = addedToSelectionElements.find((el) => el?.id === element.imageId);
            return (
              <div key={index} className={styles.element}>
                <PublicationImageCard
                  image={element.thumbnail}
                  imageId={element.imageId}
                  name={element?.originalFilename}
                  canBeDeleted={profile?.displayStatus?.toLowerCase() === VALID}
                  onDeleteImage={handleDeleteImage}
                />
                <Button
                  className={
                    isSelected
                      ? styles.selectedButton
                      : styles.button
                  }
                  onClick={() => handleAddtoSelection(element.imageId)}
                >
                  {isSelected ? t('remove_selection') : t('Add to selection')}
                </Button>
              </div>
            )
          })}
        </div>
        <Tabs sx={{paddingTop: 2}} value={valueTab} onChange={(event: React.SyntheticEvent, newValue: number) => setValueTab(newValue)} aria-label="basic tabs example">
          {uploadSession?.images && uploadSession.images.length > 0 && <Tab label="Unsaved Images" value={0} />}
          <Tab disabled={!uploadSession?.savedImages?.length} label={`${t('images_saved')} (${uploadSession?.savedImages?.length})`}  value={1} />
        </Tabs>
       <CustomTabPanel value={Number(valueTab)} indexData={0}>
         <Box>
           <div className={styles.globalDescriptionAndKeywordsContainer}>
             <FormControl component='fieldset' className={styles.selectAll}>
               <FormControlLabel
                 disabled={!addedToSelectionElements?.length}
                 control={
                   <CustomCheckbox
                     checked={
                       addedToSelectionElements.every((item) => item.checked) || false
                     }
                     onChange={handleSelectAllChange}
                   />
                 }
                 label={t('Select All')}
                 className={styles.checkbox}
               />
             </FormControl>
             <div className={styles.descriptionAndKeywordsContainer}>
               <div className={styles.descriptionContainer}>
                 <CustomTextarea
                   size='small'
                   name='description'
                   multiline
                   disabled={!addedToSelectionElements?.length}
                   variant='outlined'
                   label={t('Description')}
                   minRows={2}
                   inputProps={{
                     maxLength: MAXIMUM_CHARACTERS_DESCRIPTION
                   }}
                   value={descriptionAll}
                   // helperText={`Left: ${MAXIMUM_CHARACTERS_DESCRIPTION - (descriptionAll?.length || 0)} from ${MINIMUM_CHARACTERS_DESCRIPTION} to ${MAXIMUM_CHARACTERS_DESCRIPTION}`}
                   helperText={(
                     <span>Left: {MAXIMUM_CHARACTERS_DESCRIPTION - (descriptionAll?.length || 0)} <span
                       style={{ color: theme.palette.primary.main }}>from {MINIMUM_CHARACTERS_DESCRIPTION}</span> <span
                       style={{ color: theme.palette.primary.main }}>to {MAXIMUM_CHARACTERS_DESCRIPTION}</span></span>
                   )}
                   placeholder='Description'
                   onChange={handleDescChange}
                 />
               </div>

               <div className={styles.keywordsContainer}>
                 <InputChip allCheckbox onChangeKeywords={handleKeywordsChange} keywords={keywordsAll} allDefaultKeywords={getAllDefaultKeywords(uploadSession)}/>
               </div>
             </div>
           </div>
           <div className={styles.elements}>
             {addedToSelectionElements.map((el, index) => {
               const findElement = uploadSession?.images.find((item) => item.imageId === el?.id);
               return (
                 <div key={index} className={styles.elementContainer}>
                   <FormControlLabel
                     control={
                       <CustomCheckbox
                         checked={el.checked}
                         onChange={handleIndividualCheckboxChange(el.id)}
                       />
                     }
                     label={''}
                     className={styles.checkbox}
                   />

                   <PublicationImageCard
                     name={findElement?.originalFilename}
                     image={findElement?.thumbnail}
                     imageId={findElement?.imageId || ''}
                     canBeDeleted={false}
                   />

                   <div className={styles.elementButtonsContainer}>
                     <div className={styles.descriptionContainer}>
                       <CustomTextarea
                         size='small'
                         name='description'
                         variant='outlined'
                         placeholder='Description'
                         multiline
                         inputProps={{
                           maxLength: MAXIMUM_CHARACTERS_DESCRIPTION
                         }}
                         helperText={(
                           <span>Left: {MAXIMUM_CHARACTERS_DESCRIPTION - (findElement?.description?.length || 0)} <span
                             style={{ color: theme.palette.primary.main }}>from {MINIMUM_CHARACTERS_DESCRIPTION}</span> <span
                             style={{ color: theme.palette.primary.main }}>to {MAXIMUM_CHARACTERS_DESCRIPTION}</span></span>
                         )}
                         // helperText={`Left: ${MAXIMUM_CHARACTERS_DESCRIPTION - (findElement?.description?.length || 0)} from  ${MINIMUM_CHARACTERS_DESCRIPTION} to ${MAXIMUM_CHARACTERS_DESCRIPTION}`}
                         value={
                           uploadSession?.images.find((item) => item.imageId === el?.id)
                             ?.description || ''
                         }
                         onChange={(e: any) => {
                          const newValue = e.target.value.split('/\n/')[0].replaceAll('\n', ' ');
                           dispatch(
                             setImageProperty({
                               imageId:
                                 uploadSession?.images.find(
                                   (item) => item.imageId === el?.id
                                 )?.imageId || 0,
                               propertyName: 'description',
                               value: newValue,
                             })
                           )
                         }}
                         label={t('Description')}
                       />
                     </div>
                     <InputChip onChangeKeywords={(keyword: string[]) => {
                       dispatch(
                         setImageProperty({
                           imageId:
                             uploadSession?.images.find(
                               (item) => item.imageId === el?.id
                             )?.imageId || 0,
                           propertyName: 'keywords',
                           value: keyword,
                         })
                       )
                     }}
                                defaultKeywords={uploadSession?.images.find((item) => item.imageId === el?.id)
                                  ?.defaultKeywords || []}
                                keywords={uploadSession?.images.find((item) => item.imageId === el?.id)
                                  ?.keywords || []} />
                     <div className={styles.keywordsContainer}>
                     </div>
                   </div>
                 </div>
               )
             })}
           </div>
         </Box>
       </CustomTabPanel>
        <CustomTabPanel value={Number(valueTab)} indexData={1}>
          <SavedImages images={uploadSession?.savedImages || []} />
        </CustomTabPanel>

        {Number(valueTab) === 0 ? <div className={styles.bottomButtons}>
          <Button
            style={
              validateSaveAll(addedToSelectionElements, uploadSession) || !addedToSelectionElements?.length
              ? { color: 'white', backgroundColor: '#CCCCCC' }
              : { color: 'white', backgroundColor: '#6c757d' }
            }
            disabled={validateSaveAll(addedToSelectionElements, uploadSession)}
            className={styles.outsideButtonSave}
            onClick={() => handleOpen('save')}
          >
            {t('Save')}
          </Button>
          <Button className={styles.outsideButton} onClick={() => handleOpen('delete')}>
            {t('Delete All')}
          </Button>
        </div> : null}
      </div>
    </>
  )
}
