import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './ConfirmDialog.module.scss'

interface IProps {
  open: boolean
  handleOpen: () => void
  handleConfirm: () => void
  dialogTitle: string
  dialogMessage: string
  children?: any;
}

export const ConfirmDialog = (props: IProps) => {
  const { open, handleOpen, dialogTitle, dialogMessage, handleConfirm, children } = props

  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={handleOpen}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogMessage}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOpen} className={styles.cancelButton}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleConfirm} className={styles.confirmButton}>
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
