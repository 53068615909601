import { Button, Tab, Tabs, Typography } from '@mui/material'
import {
  SetStateAction,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

// Shared
import { TabPanel } from '../../../../../../shared-components/TabPanel'

// Scss
import styles from './Step4ImageElement.module.scss'

// Child
import { PropertyForm } from './releases-types/Property'
import { ESelectedReleaseType } from './ImageFieldArray'

// Types
import { Model } from './releases-types/Model'
import { MinorForm } from './releases-types/Minor'

// Utils
import { MINOR_RELEASE_TYPE, MODEL_RELEASE_TYPE, NavigationEnum, PROPERTY_RELEASE_TYPE } from '../../../../../../../utils/constants'

interface IProps {
  selectedImage: any,
  getValues: any,
  description: string
  setReleases?: React.Dispatch<SetStateAction<any>>
  releases?: any
  control?: any
  errors?: any;
  setValue?: any,
  watch?: any,
  selectedReleaseType: keyof typeof ESelectedReleaseType | null;
  onRemoveReleaseForm: (value: keyof typeof ESelectedReleaseType) => void;
  onSaveImageToLicensing: () => void;
}

export const Step4ImageElement = forwardRef((props: IProps, ref) => {
  const {
    selectedImage,
    getValues,
    errors,
    control,
    setValue,
    watch,
    // Selected release type
    selectedReleaseType,
    onRemoveReleaseForm,
    onSaveImageToLicensing
  } = props

  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState(`${PROPERTY_RELEASE_TYPE}[0]`)

  const currentImage = getValues('image');

  useImperativeHandle(ref, () => {
    return {
      setTabValue: (formType: keyof typeof ESelectedReleaseType | null) => setTabValue(`${formType}[0]`)
    };
  }, []);
  const constructTabs = () => {
    let newTabs = null;
    if (currentImage[PROPERTY_RELEASE_TYPE] !== undefined) {
      newTabs = {
        type: PROPERTY_RELEASE_TYPE,
        name: t('Property Release'),
        value: `${PROPERTY_RELEASE_TYPE}`
      };
    }
    if (currentImage[MODEL_RELEASE_TYPE] !== undefined) {
      newTabs = {
        type: MODEL_RELEASE_TYPE,
        name: t('Model Release'),
        value: `${MODEL_RELEASE_TYPE}`
      };
    }
    if (currentImage[MINOR_RELEASE_TYPE] !== undefined) {
      newTabs = {
        type: MINOR_RELEASE_TYPE,
        name: t('Minor Release'),
        value: `${MINOR_RELEASE_TYPE}`
      };
    }
    return newTabs
  }
  const tab = constructTabs();
  return (
    <div className={styles.container}>
      <div className={styles.containerButtonsRight}>
        <Button
          disabled={!!selectedReleaseType}
          onClick={() => onSaveImageToLicensing()}
          className={styles.button}
        >
          {t('image_to_licensing')}
        </Button>
        <Typography variant='subtitle1' fontSize={14} color={'black'}>{t('Carefully read Terms & Conditions')}&nbsp;
          <Link to={NavigationEnum.TERMS_AND_CONDITIONS} rel="noreferrer" target='_blank'  style={{ color: 'blue', textDecoration: 'none' }}>
            {t('the Terms & Conditions')}
          </Link>
        </Typography>
      </div>
      <br></br>
      {tab && <Typography paragraph align='center' color={'#c11b1e'}>{tab.name}</Typography>}
      {tab && (
        <div className={styles.tabContainer}>
          <div>
            {tab.type === PROPERTY_RELEASE_TYPE ? (
              <PropertyForm
                control={control} 
                image={selectedImage}
                name={selectedImage?.originalFilename || ''}
                watch={watch}
                errors={errors?.property || {}}
                setValue={setValue}
                getValues={getValues}
                removeForm={(formType: keyof typeof ESelectedReleaseType) => {
                  onRemoveReleaseForm(formType)
                }}
              />
            ) : tab.type === MODEL_RELEASE_TYPE ? (
              <Model
                control={control}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
                errors={errors?.model || {}}
                image={selectedImage}
                removeForm={(formType: keyof typeof ESelectedReleaseType) => {
                  onRemoveReleaseForm(formType)
                }}
              />
            ) : (
              <MinorForm
                watch={watch}
                setValue={setValue}
                getValues={getValues}
                errors={errors?.minor || {}}
                control={control}
                image={selectedImage}
                removeForm={(formType: keyof typeof ESelectedReleaseType) => {
                  onRemoveReleaseForm(formType)
                }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
});
