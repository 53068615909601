import axios from 'axios'
import { t } from 'i18next'
import Notiflix from 'notiflix'

export interface OutApiResponse {
  status: 'error' | 'success';
  message: string;
  data: any;
}

const API_BASE_URL = 'https://panel.layerstock.com/api/v1'
// const API_BASE_URL = 'http://localhost:8000/api/v1'

const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 300000,
  headers: {
    'Content-Type': 'application/json',
  },
})

export function getAuthToken() {
  return localStorage.getItem('authToken')
}

export function getLanguage() {
  return localStorage.getItem('language')
}

instance.interceptors.request.use((config) => {
  const authToken = getAuthToken()
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`
  }
  return config
})
instance.interceptors.request.use((config) => {
  const language = getLanguage()
    config.headers[`X-Localization`] = language || 'en'
  return config
})

instance.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Redirect to login page and reload the page
        localStorage.removeItem('authToken')
        const timeout = setTimeout(() => {
          window.location.href = '/login'
        }, 4000)
        Notiflix.Report.failure(
          t('You were logged out due to inactivity.'),
          t(''),
          t('OK'),
          () => {
            clearTimeout(timeout)
            window.location.href = '/login'
          }
        )
      }
      if(error?.response?.data?.message) {
        Notiflix.Report.failure(
          'Error',
          `${error?.response?.data?.message}`,
          'OK'
        )
      }
      console.error(
        'Response Error:',
        error.response.status,
        error.response.data
      )
    } else if (error.request) {
      console.error('Request Error:', error.request)
    } else {
      console.error('Error Message:', error.message)
    }
    return Promise.reject(error)
  }
)

export default instance
