import { object, string, number, mixed } from 'yup'

export const setInitialValuesProperty = (accountInfo: any) => {
  return {
    name: 'property',
    file: undefined,
    created_date: new Date(),
    owner: {
      owner_name: '',
      owner_address: '',
      owner_date_signed: null,
      owner_email: '',
      owner_phone: '',
      owner_signature: undefined
    },
    witness: {
      witness_name: ``,
      witness_date_signed: null,
      witness_signature: undefined
    },
    author: {
      author_address: `${accountInfo?.addresses?.[0]?.address || ''}`,
      author_email: `${accountInfo?.email}`,
      author_name: `${accountInfo?.firstName} ${accountInfo?.lastName}`,
      author_phone: `${accountInfo?.addresses?.[0]?.phone || ''}`,
      author_date_signed: null,
      author_signature: undefined
    }
  }
}
export const setInitialValuesModel = (accountInfo: any) => {
  return {
    name: 'model',
    file: undefined,
    model: {
      model_name: '',
      model_address: '',
      model_date_signed: null,
      model_dob: null,
      model_email: '',
      model_phone: '',
      model_signature: undefined,
      model_path: undefined
    },
    photographer: {
      photographer_address: `${accountInfo?.addresses?.[0]?.address || ''}`,
      photographer_email: `${accountInfo?.email}`,
      photographer_name: `${accountInfo?.firstName} ${accountInfo?.lastName}`,
      photographer_phone: `${accountInfo?.addresses?.[0]?.phone || ''}`,
      photographer_signature: undefined,
      photographer_date_signed: null
    },
    witness: {
      witness_name: '',
      witness_date_signed: null,
      witness_signature: undefined
    }
  }
}
export const setInitialValuesMinor = (accountInfo: any) => {
  return {
    name: 'minor',
    file: undefined,
    minor: {
      minor_name: '',
      minor_path: undefined,
      minor_dob: null
    },
    parent: {
      parent_name: '',
      parent_address: '',
      parent_phone: '',
      parent_email: '',
      parent_date_signed: null,
      parent_signature: undefined
    },
    photographer: {
      photographer_address: `${accountInfo?.addresses?.[0]?.address || ''}`,
      photographer_email: accountInfo?.email,
      photographer_name: `${accountInfo?.firstName} ${accountInfo?.lastName}`,
      photographer_phone: `${accountInfo?.addresses?.[0]?.phone || ''}`,
      photographer_date_signed: null,
      photographer_signature: undefined
    },
    witness: {
      witness_name: '',
      witness_date_signed: null,
      witness_signature: undefined
    }
  }
}

// @ts-ignore
export default object().shape({
  image:
    object().shape({
      // name: string(),
      imageId: number().required(),
      property: object().shape({
        file: mixed().notRequired(),
        owner: object().shape({
          owner_name: string().notRequired(),
          owner_address: string().notRequired(),
          owner_date_signed: string().notRequired(),
          owner_email: string().notRequired(),
          owner_phone: string().notRequired(),
          owner_signature: mixed().notRequired(),
        }),
        author: object().shape({
          author_address: string().notRequired(),
          author_email: string().notRequired(),
          author_name: string().notRequired(),
          author_phone: string().notRequired(),
          author_signature: mixed().notRequired(),
          author_date_signed: string().notRequired()
        }),
        witness: object().shape({
          witness_date_signed: string().notRequired(),
          witness_name: string().notRequired(),
          witness_signature: mixed().notRequired(),
        }),
      }),
      minor: 
        object().shape({
          file: mixed().notRequired(),
          minor: object().shape({
            minor_name: string(),
            minor_path: mixed().notRequired(),
            minor_dob: string().notRequired(),
          }),
          parent: object().shape({
            parent_name: string().notRequired(),
            parent_address: string().notRequired(),
            parent_date_signed: string().notRequired(),
            parent_email: string().notRequired(),
            parent_phone: string().notRequired(),
            parent_signature: mixed().notRequired()
          }),
          witness: object().shape({
            witness_date_signed: string().notRequired(),
            witness_name: string().notRequired(),
            witness_signature: mixed().notRequired(),
          }),
          photographer: object().shape({
            photographer_signature: mixed().notRequired(),
            photographer_date_signed: string().notRequired(),
            photographer_phone: string().notRequired(),
            photographer_name: string().notRequired(),
            photographer_email: string().notRequired(),
            photographer_address: string().notRequired(),
          }),
      }),
      model: 
        object().shape({
          file: mixed().notRequired(),
          model: object().shape({
            model_name: string().notRequired(),
            model_address: string().notRequired(),
            model_date_signed: string().notRequired(),
            model_dob: string().notRequired(),
            model_email: string().notRequired(),
            model_phone: string().notRequired(),
            model_signature: mixed().notRequired(),
            model_path: mixed().notRequired(),
          }),
          photographer: object().shape({
            photographer_address: string().notRequired(),
            photographer_email: string().notRequired(),
            photographer_name: string().notRequired(),
            photographer_phone: string().notRequired(),
            photographer_signature: mixed().notRequired(),
            photographer_date_signed: string().notRequired()
          }),
          witness: object().shape({
            witness_date_signed: string().notRequired(),
            witness_name: string().notRequired(),
            witness_signature: mixed().notRequired(),
          }),
        })
      })
})
