import {
  Alert, Avatar, Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs,
  Typography
} from '@mui/material'
import Notiflix from 'notiflix'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// Axios
import instance from '../../../../../../../providers/axios.config'

// Redux
import { RootState } from '../../../../../../../redux/reducers/rootReducer'
import {
  IRelease,
  setUploadSession
} from '../../../../../../../redux/reducers/uploadSessionReducer'
import { setImagesleftToUpload, setIsExamination, setUserData } from '../../../../../../../redux/reducers/usersReducer'

// Shared Components
import { PublicationImageCard } from '../../../../../../shared-components/card/PublicationImageCard'
import { CustomCheckbox } from '../../../../../../shared-components/checkbox/CustomCheckbox'
import { ConfirmDialog } from '../../../../../../shared-components/dialogs/ConfirmDialog'
import { CustomTabPanel } from '../../../../../../shared-components/customTabPanel'
import { ModelReleaseList } from '../../../../../../shared-components/card/ModelReleaseList'

// SCSS
import styles from './Step4.module.scss'

// Childs
import { TabValue } from '../../../AccountDetailsSeller'
import ImageFieldArray from './ImageFieldArray'

// Utils
import uploadSchemaValidator from '../../../../../../../utils/uploadSchemaValidator'
import { areObjectValuesEmpty } from '../../../../../../../utils/helpers'
import { IMinor, IModel, IProperty } from '../../../../../../../utils/types'
import { MINOR_RELEASE_TYPE, MODEL_RELEASE_TYPE, PROPERTY_RELEASE_TYPE, VALID } from '../../../../../../../utils/constants'

type Props = {
  setCurrentStep?: (key: number) => void,
  onChangeSections?: (key: TabValue) => void,
  onDeleteAllImages?: () => void;
  handleDeleteImage?: (key?: string | number) => void
}

export type ImageType = {
  name: string,
  imageId: number,
  property: IProperty[],
  model: IModel[],
  minor: IMinor[],
  saveStep?: any,
}

export const Step4 = (props: Props) => {
  const { setCurrentStep, onChangeSections, onDeleteAllImages, handleDeleteImage } = props
  const { t } = useTranslation()
  const uploadSession = useSelector(
    (state: RootState) => state.uploadSession.uploadSession
  )

  const imageFieldArrayRef: any = useRef(null);
  const [modalSaveLicensing, setModalSaveLicensing] = useState<{ isOpened: boolean; allowAi: number; }>({
    isOpened: false,
    allowAi: 1
  })
  const [modalSaveReleaseForm, setModalSaveReleaseForm] = useState(false)
  const accountInfo = useSelector((state: RootState) => state.user.usersData)
  const [openDeleteAllDialog, setOpenDeleteAllDialog] = useState(false)

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields, isDirty, isSubmitted },
    reset,
    watch,
    setError,
    getValues,
    clearErrors
  } = useForm<any>({
    defaultValues: {
      image: null
    },
    resolver: yupResolver(uploadSchemaValidator)
  })
  const { image } = getValues();
  const handleDeleteAll = () => {
    if (onDeleteAllImages) {
      onDeleteAllImages()
    }
    handleOpen()
  }
  const dispatch = useDispatch()
  const handleOpen = () => {
    setOpenDeleteAllDialog(!openDeleteAllDialog)
  }
  const [releases, setReleases] = useState<IRelease[]>([])
  const [imageSelected, setImageSelected] = useState<any>(null)
  const [valueTab, setValueTab] = useState(0);

  const handleAddtoSelection = (element: number) => {
    const formValues = getValues();
    const findImage = uploadSession?.images.find((value: any) => value.imageId === Number(element))
    setImageSelected(findImage)
    if (!formValues.image) {
      setValue('image', {
        name: '',
        imageId: element
      });
    } else {
      setImageSelected(null)
      setValue('image', null);
    }
  }

  const hasErrorPropertyForm = (data: any) => {
    let hasError: boolean = false;
    if (!data.image.property.file) {
      if (!data.image.property.author.author_email) {
        setError('image.property.author.author_email', { message: t('property_author_email_is_required') })
        hasError = true;
      }
      if (data.image.property.author.author_email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.image.property.author.author_email)) {
        setError('image.property.author.author_email', { message: t('property_author_email_invalid_format') })
        hasError = true;
      }
      if (!data.image.property.author.author_date_signed) {
        setError('image.property.author.author_date_signed', { message: t('property_author_date_signed_is_required') })
        hasError = true;
      }
      if (data.image.property.author.author_date_signed && moment(data.image.property.author.author_date_signed).isAfter(moment())) {
        setError('image.property.author.author_date_signed', { message: t('property_author_date_signed_cannot_be_in_future') })
        hasError = true;
      }
      if (!data.image.property.author.author_signature) {
        setError('image.property.author.author_signature', { message: t('property_author_signature_is_required') })
        hasError = true;
      }
      if (!data.image.property.owner.owner_name) {
        setError('image.property.owner.owner_name', { message: t('property_owner_name_is_required') })
        hasError = true;
      }
      if (!data.image.property.owner.owner_date_signed) {
        setError('image.property.owner.owner_date_signed', { message: t('property_owner_date_signed_is_required') })
        hasError = true;
      }
      if (data.image.property.owner.owner_date_signed && moment(data.image.property.owner.owner_date_signed).isAfter(moment())) {
        setError('image.property.owner.owner_date_signed', { message: t('property_owner_date_signed_cannot_be_in_future') })
        hasError = true;
      }
      if (!data.image.property.owner.owner_signature) {
        setError('image.property.owner.owner_signature', { message: t('property_owner_signature_is_required') })
        hasError = true;
      }
      if (data.image.property.owner.owner_email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.image.property.owner.owner_email)) {
        setError('image.property.owner.owner_email', { message: t('property_owner_email_invalid_format') })
        hasError = true;
      }
      if (!data.image.property.witness.witness_name) {
        setError('image.property.witness.witness_name', { message: t('property_witness_name_is_required') })
        hasError = true;
      }
      if (!data.image.property.witness.witness_date_signed) {
        setError('image.property.witness.witness_date_signed', { message: t('property_witness_date_signed_is_required') })
        hasError = true;
      }
      if (data.image.property.witness.witness_date_signed && moment(data.image.property.witness.witness_date_signed).isAfter(moment())) {
        setError('image.property.witness.witness_date_signed', { message: t('property_witness_date_signed_cannot_be_in_future') })
        hasError = true;
      }
      if (!data.image.property.witness.witness_signature) {
        setError('image.property.witness.witness_signature', { message: t('property_witness_signature_is_required') })
        hasError = true;
      }
    }
    return hasError;
  };

  const hasErrorModelForm = (data: any) => {
    let hasError: boolean = false;
    if (!data.image.model.file) {
      if (!data.image.model.photographer.photographer_email) {
        setError('image.model.photographer.photographer_email', { message: t('model_photographer_email_is_required') })
        hasError = true;
      }
      if (data.image.model.photographer.photographer_email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.image.model.photographer.photographer_email)) {
        setError('image.model.photographer.photographer_email', { message: t('model_photographer_email_invalid_format') })
        hasError = true;
      }
      if (!data.image.model.photographer.photographer_signature) {
        setError('image.model.photographer.photographer_signature', { message: t('model_photographer_signature_is_required') })
        hasError = true;
      }
      if (!data.image.model.photographer.photographer_date_signed) {
        setError('image.model.photographer.photographer_date_signed', { message: t('model_photographer_date_signed_is_required') })
        hasError = true;
      }
      if (data.image.model.photographer.photographer_date_signed && moment(data.image.model.photographer.photographer_date_signed).isAfter(moment())) {
        setError('image.model.photographer.photographer_date_signed', { message: t('model_photographer_date_signed_cannot_be_in_future') })
        hasError = true;
      }
      if (!data.image.model.model.model_name) {
        setError('image.model.model.model_name', { message: t('model_name_is_required') })
        hasError = true;
      }
      if (!data.image.model.model.model_signature) {
        setError('image.model.model.model_signature', { message: t('model_model_signature_is_required') })
        hasError = true;
      }
      if (!data.image.model.model.model_date_signed) {
        setError('image.model.model.model_date_signed', { message: t('model_model_date_signed_is_required') })
        hasError = true;
      }
      if (data.image.model.model.model_date_signed && moment(data.image.model.model.model_date_signed).isAfter(moment())) {
        setError('image.model.model.model_date_signed', { message: t('model_model_date_signed_cannot_be_in_future') })
        hasError = true;
      }
      if (data.image.model.model.model_email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.image.model.model.model_email)) {
        setError('image.model.model.model_email', { message: t('model_model_email_invalid_format') })
        hasError = true;
      }
      // if (!data.image.model.model.model_dob) {
      //   setError('image.model.model.model_dob', { message: t('model_model_dob_is_required') })
      //   hasError = true;
      // }
      if (data.image.model.model.model_dob && moment(data.image.model.model.model_dob).isAfter(moment())) {
        setError('image.model.model.model_dob', { message: t('model_model_dob_cannot_be_in_future') })
        hasError = true;
      }
      if (!data.image.model.witness.witness_name) {
        setError('image.model.witness.witness_name', { message: t('model_witness_name_is_required') })
        hasError = true;
      }
      if (!data.image.model.witness.witness_date_signed) {
        setError('image.model.witness.witness_date_signed', { message: t('model_witness_date_signed_is_required') })
        hasError = true;
      }
      if (data.image.model.witness.witness_date_signed && moment(data.image.model.witness.witness_date_signed).isAfter(moment())) {
        setError('image.model.witness.witness_date_signed', { message: t('model_witness_date_signed_cannot_be_in_future') })
        hasError = true;
      }
      if (!data.image.model.witness.witness_signature) {
        setError('image.model.witness.witness_signature', { message: t('model_witness_signature_is_required') })
        hasError = true;
      }
    } else {
      if (!data.image.model.model.model_name) {
        setError('image.model.model.model_name', { message: t('model_name_is_required') })
        hasError = true;
      }
    }
    return hasError;
  };

  const hasErrorMinorForm = (data: any) => {
    let hasError: boolean = false;
    if (!data.image.minor.file) {
      if (!data.image.minor.photographer.photographer_email) {
        setError('image.minor.photographer.photographer_email', { message: t('minor_photographer_email_is_required') })
        hasError = true;
      }
      if (data.image.minor.photographer.photographer_email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.image.minor.photographer.photographer_email)) {
        setError('image.minor.photographer.photographer_email', { message: t('minor_photographer_email_invalid_format') })
        hasError = true;
      }
      if (!data.image.minor.photographer.photographer_signature) {
        setError('image.minor.photographer.photographer_signature', { message: t('model_photographer_signature_is_required') })
        hasError = true;
      }
      if (!data.image.minor.photographer.photographer_date_signed) {
        setError('image.minor.photographer.photographer_date_signed', { message: t('minor_photographer_date_signed_is_required') })
        hasError = true;
      }
      if (data.image.minor.photographer.photographer_date_signed && moment(data.image.minor.photographer.photographer_date_signed).isAfter(moment())) {
        setError('image.minor.photographer.photographer_date_signed', { message: t('minor_photographer_date_signed_cannot_be_in_future') })
        hasError = true;
      }
      if (!data.image.minor.minor.minor_name) {
        setError('image.minor.minor.minor_name', { message: t('minor_name_is_required') })
        hasError = true;
      }
      // if (!data.image.minor.minor.minor_dob) {
      //   setError('image.minor.minor.minor_dob', { message: t('minor_minor_dob_is_required') })
      //   hasError = true;
      // }
      if (data.image.minor.minor.minor_dob && moment(data.image.minor.minor.minor_dob).isAfter(moment())) {
        setError('image.minor.minor.minor_dob', { message: t('minor_minor_dob_cannot_be_in_future') })
        hasError = true;
      }
      if (data.image.minor.minor.minor_dob && moment(data.image.minor.minor.minor_dob).isSameOrBefore(moment().subtract(18, 'years'))) {
        setError('image.minor.minor.minor_dob', { message: t('minor_minor_dob_should_be_minor') })
        hasError = true;
      }
      if (!data.image.minor.parent.parent_name) {
        setError('image.minor.parent.parent_name', { message: t('parent_name_is_required') })
        hasError = true;
      }
      if (!data.image.minor.parent.parent_signature) {
        setError('image.minor.parent.parent_signature', { message: t('minor_parent_signature_is_required') })
        hasError = true;
      }
      if (!data.image.minor.parent.parent_date_signed) {
        setError('image.minor.parent.parent_date_signed', { message: t('minor_parent_date_signed_is_required') })
        hasError = true;
      }
      if (data.image.minor.parent.parent_date_signed && moment(data.image.minor.parent.parent_date_signed).isAfter(moment())) {
        setError('image.minor.parent.parent_date_signed', { message: t('minor_parent_date_signed_cannot_be_in_future') })
        hasError = true;
      }
      if (!data.image.minor.witness.witness_name) {
        setError('image.minor.witness.witness_name', { message: t('minor_witness_name_is_required') })
        hasError = true;
      }
      if (!data.image.minor.witness.witness_date_signed) {
        setError('image.minor.witness.witness_date_signed', { message: t('minor_witness_date_signed_is_required') })
        hasError = true;
      }
      if (data.image.minor.witness.witness_date_signed && moment(data.image.minor.witness.witness_date_signed).isAfter(moment())) {
        setError('image.minor.witness.witness_date_signed', { message: t('minor_witness_date_signed_cannot_be_in_future') })
        hasError = true;
      }
      if (!data.image.minor.witness.witness_signature) {
        setError('image.minor.witness.witness_signature', { message: t('minor_witness_signature_is_required') })
        hasError = true;
      }
    } else {
      if (!data.image.minor.minor.minor_name) {
        setError('image.minor.minor.minor_name', { message: t('minor_name_is_required') })
        hasError = true;
      }
    }
    return hasError;
  };
  const handleSaveFormData = (data: any, formData: any) => {
    const elementAppend = `releases`;
    let hasError = false;
    if (data.image?.property !== undefined) {
      hasError = hasErrorPropertyForm(data);
    }
    if (data.image?.model !== undefined) {
      hasError = hasErrorModelForm(data);
    }
    if (data.image?.minor !== undefined) {
      hasError = hasErrorMinorForm(data);
    }
    if (hasError) {
      return false;
    }
    formData.append(`${elementAppend}[imageId]`, data.image.imageId.toString());
      if (data.image?.property) {
        if (data.image.property.file) {
          // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
          formData.append(`${elementAppend}[${PROPERTY_RELEASE_TYPE}][file]`, data.image.property.file)
        } else if (!areObjectValuesEmpty(data.image.property.owner)) {
          // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
          Object.keys(data.image.property.owner).map((key: string) => {
            formData.append(`${elementAppend}[${PROPERTY_RELEASE_TYPE}][owner][${key}]`, key === 'owner_date_signed' ? moment(data.image.property.owner?.owner_date_signed).format('YYYY-MM-DD') : data.image.property.owner?.[key])
          })
          Object.keys(data.image.property.author).map((key?: string) => {
            formData.append(`${elementAppend}[${PROPERTY_RELEASE_TYPE}][author][${key}]`, key === 'author_date_signed' ? moment(data.image.property.author?.[key]).format('YYYY-MM-DD') : key ? data.image.property.author?.[key] : '')
          })
          if (!areObjectValuesEmpty(data.image.property.witness)) {
            formData.append(`${elementAppend}[${PROPERTY_RELEASE_TYPE}][witness][witness_name]`, data.image.property.witness?.witness_name)
            formData.append(`${elementAppend}[${PROPERTY_RELEASE_TYPE}][witness][witness_date_signed]`, moment(data.image.property.witness?.witness_date_signed).format('YYYY-MM-DD'))
            formData.append(`${elementAppend}[${PROPERTY_RELEASE_TYPE}][witness][witness_signature]`, data.image.property.witness?.witness_signature)
          }
        } else {
          return null
        }
    }
    if (data.image?.minor) {
      if (data.image.minor?.file) {
        // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
        formData.append(`${elementAppend}[${MINOR_RELEASE_TYPE}][file]`, data.image.minor?.file)
        formData.append(`${elementAppend}[${MINOR_RELEASE_TYPE}][${MINOR_RELEASE_TYPE}][minor_name]`, data.image.minor?.minor?.minor_name)
      } else if (!areObjectValuesEmpty(data.image.minor?.minor) || !areObjectValuesEmpty(data.image.minor?.parent)) {
        // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
        // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
        Object.keys(data.image.minor?.photographer).map((key?: string) => {
          formData.append(`${elementAppend}[${MINOR_RELEASE_TYPE}][photographer][${key}]`, key === 'photographer_date_signed' ? moment(data.image.minor?.photographer?.[key]).format('YYYY-MM-DD') : key ? data.image.minor?.photographer?.[key] : '')
        })
        Object.keys(data.image.minor?.minor).map((key?: string) => {
          if (key !== undefined) {
            if (key === 'minor_path') {
              formData.append(`${elementAppend}[${MINOR_RELEASE_TYPE}][${MINOR_RELEASE_TYPE}][${key}]`, data.image.minor?.minor?.[key] || '');
            } else if (key === 'minor_dob') {
              formData.append(`${elementAppend}[${MINOR_RELEASE_TYPE}][${MINOR_RELEASE_TYPE}][${key}]`, data.image.minor?.minor?.[key] ? moment(data.image.minor?.minor?.[key]).format('YYYY-MM-DD') : '');
            } else {
              formData.append(`${elementAppend}[${MINOR_RELEASE_TYPE}][${MINOR_RELEASE_TYPE}][${key}]`, data.image.minor?.minor?.[key]);
            }
          }
          return formData;
        })
        Object.keys(data.image.minor?.parent).map((key?: string) => {
          formData.append(`${elementAppend}[${MINOR_RELEASE_TYPE}][parent][${key}]`, key === 'parent_date_signed' ? moment(data.image.minor?.parent?.[key]).format('YYYY-MM-DD') : key ? data.image.minor?.parent?.[key] : '')
        })
        if (data.image.minor?.witness?.witness_name) {
          formData.append(`${elementAppend}[${MINOR_RELEASE_TYPE}][witness][witness_name]`, data.image.minor?.witness?.witness_name)
          formData.append(`${elementAppend}[${MINOR_RELEASE_TYPE}][witness][witness_date_signed]`, moment(data.image.minor?.witness?.witness_date_signed).format('YYYY-MM-DD'))
          formData.append(`${elementAppend}[${MINOR_RELEASE_TYPE}][witness][witness_signature]`, data.image.minor?.witness?.witness_signature)
        }
      } else {
        return null
      }
    }
    if (data.image?.model) {
      if (data.image.model?.file) {
        // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
        formData.append(`${elementAppend}[${MODEL_RELEASE_TYPE}][file]`, data.image.model?.file)
        formData.append(`${elementAppend}[${MODEL_RELEASE_TYPE}][${MODEL_RELEASE_TYPE}][model_name]`, data.image.model?.model?.model_name)
      } else if (!areObjectValuesEmpty(data.image.model?.model) || !areObjectValuesEmpty(data.image.model?.witness)) {
        // formData.append(`${elementAppend}[imageId]`, data.imageId.toString());
        if (!areObjectValuesEmpty(data.image.model?.model)) {
          Object.keys(data.image.model?.model).map((key: string) => {
            if (data.image.model?.model?.[key]) {
              formData.append(`${elementAppend}[${MODEL_RELEASE_TYPE}][${MODEL_RELEASE_TYPE}][${key}]`, (key === 'model_dob' || key === 'model_date_signed') ? moment(data.image.model?.model?.[key]).format('YYYY-MM-DD') : data.image.model?.model?.[key])
            }
          })
        }
        if (!areObjectValuesEmpty(data.image.model?.witness)) {
          Object.keys(data.image.model?.witness).map((key: string) => {
            formData.append(`${elementAppend}[${MODEL_RELEASE_TYPE}][witness][${key}]`, key === 'witness_date_signed' ? moment(data.image.model?.witness_date_signed?.[key]).format('YYYY-MM-DD') : data.image.model?.witness?.[key])
          })
        }
        Object.keys(data.image.model?.photographer).map((key: string) => {
          formData.append(`${elementAppend}[${MODEL_RELEASE_TYPE}][photographer][${key}]`, key === 'photographer_date_signed' ? moment(data.image.model?.photographer?.[key]).format('YYYY-MM-DD') : data.image.model?.photographer?.[key])
        })
      } else {
        return null
      }
    }
    return formData
  }
  const saveReleaseForm = async () => {
    setModalSaveReleaseForm(false)
    const config = {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>' // Set the Content-Type header for FormData
      }
    }
    const formDataSave = new FormData()
    
    const formData = handleSaveFormData(getValues(), formDataSave);
    if (!formData) {
      return false;
    }
    Notiflix.Loading.standard();
    
    formData.append('nextStep', 0)
    try {
      const saveData = await instance.post(
        `/session/upload`,
        formData,
        config
      ) as any
      if (saveData?.status === 'error') {
        Notiflix.Report.failure(
          'Error',
          `${saveData?.message}`,
          'OK'
        )
      } else {
        imageFieldArrayRef.current?.cleanReleaseFormAfterSaving();
        Notiflix.Notify.success(t('Your release has been saved'))

      }
      if (onChangeSections) {
        onChangeSections('PUBLICATION')
      }
      await sessionRetrieve()
      Notiflix.Loading.remove();
    } catch (error: any) {
      Notiflix.Loading.remove();
      Notiflix.Report.failure(
        'Error',
        'Failed to update images. Please try again later.',
        'OK'
      )
    }
  }
  const sendImageToLicensing = async () => {
    Notiflix.Loading.standard();
    try {
      const saveData = await instance.post(
        `/session/upload`,
        {
          releases: {
            imageId: imageSelected.imageId
          },
          can_used_for_ai: modalSaveLicensing.allowAi,
          nextStep: uploadSession?.images.length === 1 ? 2 : 1,
        }
      ) as any
      if (saveData?.status === 'error') {
        Notiflix.Report.failure(
          'Error',
          `${saveData?.message}`,
          'OK'
        )
      }
      if (saveData?.status === 'success') {
        imageFieldArrayRef.current?.cleanReleaseFormAfterSaving();
        setValue('image', null);
        setImageSelected(null);
        setModalSaveLicensing({ isOpened: false, allowAi: 1 });
        dispatch(setIsExamination(saveData?.data?.inExamination)) // for image to licensing
        dispatch(setImagesleftToUpload(accountInfo?.noImagesLeftToUpload - 1))
        const response = await instance.get('user/profile') // for image to licensing
        dispatch(setUserData({...response.data})) // for image to licensing 
        Notiflix.Notify.success(t('The image has been sent to licensing'))
      }
      if (typeof onChangeSections !== 'undefined') {
        onChangeSections('PUBLICATION')
      }
      await sessionRetrieve()
      Notiflix.Loading.remove();
    } catch (error: any) {
      Notiflix.Loading.remove();
      Notiflix.Report.failure(
        'Error',
        t('error_api'),
        'OK'
      )
    }
  }

  const handleSaveReleases = async () => {
    setModalSaveReleaseForm(!modalSaveReleaseForm)
  }

  const sessionRetrieve = async () => {
    try {
      const res = await instance.get('session/retrieve')
      if (!res?.data?.step) {
        if (setCurrentStep) {
          setCurrentStep(1)
        }
        dispatch(setUploadSession(res.data))
      }
      else {
        dispatch(setUploadSession(res.data))
      }
    } catch (error: any) {
      Notiflix.Report.failure(
        'Error',
        'Failed to update images. Please try again later.',
        'OK'
      )
    }
  }
  return (
    <>
      <ConfirmDialog
        open={openDeleteAllDialog}
        handleOpen={handleOpen}
        handleConfirm={handleDeleteAll}
        dialogMessage={t('Are you sure to delete all of the images')}
        dialogTitle={t('Delete All Images')}
      />
      <form onSubmit={handleSubmit(handleSaveReleases)}>
        <div>
          <div className={styles.titleContainer}>
            <span className={styles.step}>{t('Step', { step: 4 })}</span>
            <span className={styles.title}>
            {t('Property and Model Realeases')}
          </span>
          </div>
          <div className={styles.description}>{t('Step4_description')}</div>
          <br/>
          <Alert severity='info'>
            {t('The images can be sent to licensing separately')}
          </Alert>

          <div className={styles.topElementsContainer}>
            {uploadSession?.images.map((element, index) => (
              <div key={element.imageId} className={styles.element}>
                <PublicationImageCard
                  image={element.thumbnail}
                  imageId={element.imageId}
                  name={element?.originalFilename}
                  canBeDeleted={accountInfo?.displayStatus?.toLowerCase() === VALID}
                  onDeleteImage={handleDeleteImage}
                />
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                  <Button
                    disabled={image && image.imageId !== element?.imageId}
                    variant={image && image.imageId === element?.imageId ? 'contained' : 'outlined'}
                    size='small'
                    onClick={() => handleAddtoSelection(element.imageId)}
                  >
                    {image && image.imageId === element?.imageId ? t('remove_selection') : t('Add to selection')}
                  </Button>
                </Box>
              </div>
            ))}
          </div>
          <Tabs sx={{paddingTop: 2}} value={valueTab} onChange={(event: React.SyntheticEvent, newValue: number) => setValueTab(newValue)} aria-label="basic tabs example">
            <Tab label="Unsaved Images" value={0} />
            <Tab disabled={!uploadSession?.savedImages?.length} label={`${t('images_saved')} (${uploadSession?.savedImages?.length})`}  value={1} />
          </Tabs>
          <CustomTabPanel value={Number(valueTab)} indexData={0}>
            <div className={styles.containerStepFourAlerts}>
              {(uploadSession?.images || []).filter((img: any) => img.isAdult === 1)?.length 
                ? (<Alert severity='warning'>
                    {t('info_step4_images_adult', {
                      files: ((uploadSession?.images || []).filter((img: any) => img.isAdult === 1) ||[]).map((d: any) => `${d.originalFilename}`).join(', '),
                    })}
                  </Alert>) 
                : null
              }
              {(uploadSession?.images || []).filter((img: any) => img.isAi === 1)?.length 
                ? (
                  <>
                    <Alert severity='warning'>
                      {t('info_step4_images_ai', {
                        files: ((uploadSession?.images || []).filter((img: any) => img.isAi === 1) ||[]).map((d: any) => `${d.originalFilename}`).join(', '),
                      })}
                    </Alert>
                    <Alert severity='warning'>
                      {t('info_step4_images_ai_second')}
                    </Alert>
                  </>
                ) 
                : null
              }
            </div>
            {image && (
              <div className={styles.elementContainer}>
                <ImageFieldArray
                  ref={imageFieldArrayRef}
                  imageField={image}
                  control={control}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  getValues={getValues}
                  releases={releases}
                  setReleases={setReleases} 
                  clearErrors={clearErrors}
                  onSaveImageToLicensing={() => {
                    setModalSaveLicensing({
                      isOpened: true,
                      allowAi: 1
                    })
                  }}
                />
              </div>
            )}
            <Divider />
            <div className={styles.bottom}>
              <div className={styles.bottomButtons}>
                {/*<Button className={styles.outsideButton} onClick={handleSaveAll}>{t('Save All')}</Button>*/}
                <Button className={styles.outsideButton} onClick={handleOpen}>
                  {t('Delete All')}
                </Button>
              </div>
            </div>
            <br />
            {errors?.images && <Alert severity='warning'>
              {t('releases_required')}
            </Alert>}
          </CustomTabPanel>
          <CustomTabPanel  value={Number(valueTab)} indexData={1}>
            <div className={styles.elements}>
              <TableContainer component={Paper} className={styles.table}>
                <Table>
                  <TableHead>
                    <TableRow className={styles.tableRow}>
                      <TableCell>{t('Image')}</TableCell>
                      <TableCell align='left'>{t('Image ID')}</TableCell>
                      <TableCell align='left'>{t('Releases')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(uploadSession?.savedImages || []).map((image: any) => (
                      <TableRow key={image?.id} className={styles.tableRow}>
                        <TableCell component='th' scope='row'>
                          <Avatar
                            sx={{ width: 60, height: 60 }}
                            alt={image?.title}
                            variant='rounded'
                            src={image?.thumbnail}
                          />
                        </TableCell>
                        <TableCell align='left'>{image?.imageId}</TableCell>
                        <TableCell align='left'>
                          {
                            <ModelReleaseList
                              releases={image?.releases || []}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </CustomTabPanel>
        </div>
      </form>
      <ConfirmDialog
        open={modalSaveLicensing.isOpened}
        handleOpen={() => {
          setModalSaveLicensing({
            isOpened: !modalSaveLicensing.isOpened,
            allowAi: modalSaveLicensing.isOpened ? 1 : modalSaveLicensing.allowAi 
          })
        }}
        handleConfirm={sendImageToLicensing}
        dialogMessage={t('Do you want to send this image for licensing?')}
        dialogTitle={t('Licensing')}
      >
        {imageSelected && imageSelected.isAi > 0 && (
          <Alert severity='info'>
            {t('As the image has the AI option, you automatically agree that the image can be used in AI Generation')}
          </Alert>
        )}
        <FormControl component='fieldset'>
          <FormControlLabel
            control={
              <CustomCheckbox
                disabled={imageSelected && imageSelected.isAi}
                checked={!!modalSaveLicensing.allowAi}
                onChange={(e: any) => {
                  console.log('asdasds');
                  setModalSaveLicensing({ ...modalSaveLicensing, allowAi: e.target.checked ? 1 : 0 });
                }}
              />
            }
            label={t('I allow the use in AI generation')}
            className={styles.bottomCheckbox}
          />
        </FormControl>
      </ConfirmDialog>
      <ConfirmDialog
        open={modalSaveReleaseForm}
        handleOpen={handleSaveReleases}
        handleConfirm={saveReleaseForm}
        dialogMessage={t('Do you want to save this release form for this image?')}
        dialogTitle={t('Save release form')}
      />
    </>
  )
}

