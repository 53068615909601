// Axios
import api, { OutApiResponse } from '../../axios.config';

export const deleteReleaseAPI = async (releaseId: number) => {
  try {
    const response:OutApiResponse  = await api.delete(`seller/images/releases/${releaseId}`);
    if (response.status === 'success') {
      return {
        status: 'success',
        message: response.message
      };
    }
    return {
      status: 'error',
      message: response.message
    };
  } catch (e) {
    return {
      status: 'error',
      message: 'An error occurred. Please try again later'
    };
  }
};