import { Alert, Box, Step, StepLabel, Stepper } from '@mui/material'
import i18next from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '../../Title'
import { Step1 } from './Step1'
import { Step2 } from './Step2'
import { Step3 } from './Step3'
import { Step4 } from './Step4'
import styles from './Upload.module.scss'
import Notiflix from 'notiflix'
import instance from '../../../../../../../providers/axios.config'
import { setUploadSession, setUploadSessionStep1 } from '../../../../../../../redux/reducers/uploadSessionReducer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'
import ButtonNext from './ButtonNext'
import Backdrop from '@mui/material/Backdrop'
import { typeResponse } from '../../../../../../../utils/types'
import { setUserData } from '../../../../../../../redux/reducers/usersReducer'

const steps = [
  i18next.t('Upload Formats/Photos'),
  i18next.t('Options'),
  i18next.t('Description and Keywords'),
  i18next.t('Property and Model Realeases'),
]

export const Upload = () => {
  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = useState(1)
  const [firstStepFiles, setFirstStepFiles] = useState<File[]>([])
  const dispatch = useDispatch()
  const uploadSession = useSelector(
    (state: RootState) => state.uploadSession.uploadSession
  )
  const account = useSelector((state: RootState) => state.user.usersData)


  const handleUpload = async (formData: any, save:  1 | 0 = 1) => {
    const config = {
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>', // Set the Content-Type header for FormData
      },
    }
    formData.append('nextStep', JSON.stringify(save))
    try {
      // Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.post('session/upload', formData, config) as typeResponse;
      if ((resp as any).status === 'success') {
        setCurrentStep((prev) => prev + 1)
        dispatch(setUploadSessionStep1({
          images: {jpg: [], eps: [], png: []},
          unsavedImages: []
        }))
      } else {
        Notiflix.Loading.remove()
        Notiflix.Report.failure(
          'Error',
          `${resp?.message}`,
          'OK'
        )
      }
    } catch (error) {
      Notiflix.Loading.remove()
      // Handle error
      console.error('Error uploading files:', error)
    }
  }

  const step1Save = async (formData: any, save: 1 | 0 = 1) => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' }) // Show loading indicator
    await handleUpload(formData, save)
  }

  const step2Save = async (save: 1 | 0 = 1) => {
    const imagesToUpdate = uploadSession?.images

    if (imagesToUpdate && imagesToUpdate.length > 0) {
      try {
        Notiflix.Loading.standard({ svgColor: '#c11b1e' }) // Show loading indicator

        // Create an array of updated images data using map
        const updatedImagesData = imagesToUpdate.map((image) => ({
          imageId: image.imageId,
          is_ai: Number(image.isAi),
          is_adult: Number(image.isAdult),
          is_editorial: Number(image.isEditorial),
          network_ai: image.networkAi,
        }))

        // Make the API call with updated images data
        const resp = await instance.post(
          `session/upload`,
          {
            nextStep: save,
            options: updatedImagesData,
          }
        )
        if (resp?.data?.status === 'error') {
          return Notiflix.Report.failure(
            t('error'),
            resp?.data?.message || t('error_api'),
            t('ok')
          )
        }

        // Show success notification
        Notiflix.Notify.success(t('Success'))
      } catch (error) {
        Notiflix.Report.failure(
          t('error'),
          t('session_upload_failed'),
          t('ok')
        )
      } finally {
        Notiflix.Loading.remove() // Remove loading indicator regardless of success or failure
      }
    }
  }

  const step3Save = async (save: 1 | 0 = 1) => {
    let response: any = {};
    const imagesToUpdate = uploadSession?.images
    const validateSave = uploadSession?.images.some((image) => image.description?.length < 30) ||
      uploadSession?.images.some(
        (image) =>
          image.keywords?.length < 7);
    const checkValidationByStep = save === 0 || !uploadSession?.images?.length ? false : validateSave;
    if (checkValidationByStep) {
      return
    }
    if ((imagesToUpdate && imagesToUpdate.length > 0) || !uploadSession?.images?.length) {
      try {
        Notiflix.Loading.standard({ svgColor: '#c11b1e' }) // Show loading indicator

        const findElementSelected = (imagesToUpdate || []).filter((element: any) => element.description?.length > 30 && element?.keywords?.length > 6)
        const updatedImagesDataSave = findElementSelected.map((image) => ({
          imageId: image.imageId,
          keywords: image?.defaultKeywords?.length ? [...image.defaultKeywords, ...image.keywords] : [...image.keywords],
          description: image.description,
        }))
        // Create an array of updated images data using map
        const updatedImagesData = (imagesToUpdate || []).map((image) => ({
          imageId: image.imageId,
          keywords: image?.defaultKeywords?.length ? [...image.defaultKeywords, ...image.keywords] : [...image.keywords],
          description: image.description,
        }))
        if(!uploadSession?.images?.length) {
          const resp = await instance.post(
            `session/upload`,
            {
              nextStep: save,
            }
          ) as typeResponse;
          response = resp;
          if ((resp as any).status === 'success') {
            await getUploadSession()
            Notiflix.Notify.success(t('Success'))
          } else {
            Notiflix.Report.failure(
              t('error'),
              `${resp?.message}`,
              t('ok')
            )
          }
          return resp;
        } else {
          const resp = await instance.post(
            `session/upload`,
            {
              nextStep: save,
              description_and_keywords: save === 0 ? updatedImagesDataSave : updatedImagesData,
            }
          ) as typeResponse;
          response = resp;
          if ((resp as any).status === 'success') {
            await getUploadSession()
            Notiflix.Notify.success(t('Success'))
          } else {
            Notiflix.Report.failure(
              t('error'),
              `${resp?.message}`,
              t('ok')
            )
          }
          return resp;
        }

        // Make the API call with updated images data
      } catch (error) {
        // Handle error
        console.error('Error updating images:', error)

        // Show error notification
        Notiflix.Report.failure(
          t('error'),
          t('session_upload_failed'),
          t('ok')
        )
      } finally {
        Notiflix.Loading.remove() // Remove loading indicator regardless of success or failure
      }
      return response;
    }
  }

  const goToNextStep = async (save: 1 | 0 = 1) => {
    const formData = new FormData()
    if (currentStep === 1) {
      await step1Save(formData, save)
    } else if (currentStep === 2) {
     await step2Save(save)
    } else if (currentStep === 3) {
      return await step3Save(save)
    }

    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' }) // Show loading indicator
      const res = await instance.get('session/retrieve')
      if (res.data.step !== 1) {
        setCurrentStep(res.data.step)
        dispatch(setUploadSession(res.data))
      } else {
        if(res?.data) {
          dispatch(setUploadSessionStep1(res?.data))
        }
      }
      Notiflix.Loading.remove()
    } catch (error) {
      // Show error notification
      Notiflix.Report.failure(
        t('error'),
        t('session_upload_failed'),
        t('ok')
      )
    } finally {
      Notiflix.Loading.remove() // Remove loading indicator regardless of success or failure
    }
  }
  const getUploadSession = async () => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const res = await instance.get('session/retrieve') as typeResponse;
      if (res.data?.step && res.data.step !== 1) {
        setCurrentStep(res.data?.step)
        dispatch(setUploadSession(res.data))
      } else {
        if(res?.data) {
          dispatch(setUploadSessionStep1(res?.data))
        }
      }
    } catch (error) {
      // Handle error
      console.error('Error fetching upload session:', error)
      Notiflix.Report.failure(
        'Error',
        'Failed to fetch upload session data. Please try again later.',
        'OK'
      )
    } finally {
      Notiflix.Loading.remove() // Remove loading indicator regardless of success or failure
    }
  }
  useEffect(() => {
    if (uploadSession) {
      setCurrentStep(uploadSession.step)
    }
    if (!uploadSession) getUploadSession()
  }, [])



  const handleDeleteAllImages = async () => {
    try {
      Notiflix.Loading.standard({ svgColor: '#c11b1e' })
      const resp = await instance.delete(`session/delete`)
      if ((resp as any).status === 'success') {
        setCurrentStep(1)
        setFirstStepFiles([])
        dispatch(setUploadSession(null))
        const response = await instance.get('user/profile')
        dispatch(setUserData({...response.data}))
      } else {

        dispatch(setUploadSessionStep1(resp?.data))
      }
    } catch (error) {
      // Handle error
      console.error('Error delete files:', error)
    } finally {
      Notiflix.Loading.remove()
    }
  }
  const handleDeleteImage= async (id: any) => {
    if(uploadSession && uploadSession?.images?.length > 1 && id) {
      try {
        Notiflix.Loading.standard({ svgColor: '#c11b1e' })
        const resp = await instance.delete(`session/delete?images[0]=${id}`)
        if ((resp as any).status === 'success') {
          const deleteImage = uploadSession.images.filter((image) => image.imageId !== id) as any;
          dispatch(setUploadSession({...uploadSession, images: [...deleteImage]}))
        }
      } catch (error) {
        // Handle error
        console.error('Error delete files:', error)
      } finally {
        Notiflix.Loading.remove()
      }
    } else {
      await handleDeleteAllImages()
    }
  }

  return (
    <div className={styles.container} style={{position: "relative"}}>
      <Title title={t('Upload Files')} description={t('Maximum 24 images')} />

      <div className={styles.content}>
        <Box
          sx={{
            marginBottom: '20px',
            '.MuiStepper-root': {
              flexDirection: { xs: 'column', md: 'row' },
            },
            '.MuiStep-root': {
              margin: { xs: 1, md: 0 },
            },
          }}
        >
          <Stepper activeStep={currentStep - 1}>
            {steps.map((label, index) => {
              return (
                <Step
                  key={label}
                  sx={{
                    '& .MuiStepLabel-root .Mui-completed': {
                      color: '#c11b1e',
                    },
                    '& .MuiStepLabel-root .Mui-active': {
                      color: '#1b50c1',
                    },
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Box>

        {currentStep === 1 && <Step1  maxLength={24}
                                       setFirstStepFiles={setFirstStepFiles} />}

        {currentStep === 2 && <Step2 onDeleteAllImages={handleDeleteAllImages} saveStep={() => goToNextStep(0)}/>}

        {currentStep === 3 && <Step3 onDeleteAllImages={handleDeleteAllImages} saveStep={() => goToNextStep(0)} handleDeleteImage={handleDeleteImage}/>}

        {currentStep === 4 && <Step4  setCurrentStep={setCurrentStep}  handleDeleteImage={handleDeleteImage} onDeleteAllImages={handleDeleteAllImages}/>}
        {currentStep < 4 && (
          <>
            <ButtonNext isExamination={account?.inExamination} currentStep={currentStep} onNextStep={goToNextStep} />
          </>
        )}
      </div>
      {/* <Backdrop
        sx={{ position: 'absolute', opacity: 0.1, zIndex: (theme) => theme.zIndex.drawer -1 }}
        open={!account?.id_file || false}
      >
        <Alert severity="warning">
          {t('alert_identity_file')}
        </Alert>
      </Backdrop> */}
    </div>
  )
}
