import { Route, Routes } from 'react-router-dom'
import { NavigationEnum, ROUTES } from '../../../utils/constants'
import { PlansPrices } from '../plans-prices/PlansPrices'
import { AssetDetails } from './asset-details/AssetDetails'
import { Assets } from './assets/Assets'
import { ForgotPassword } from './authentication/ForgotPassword'
import { Login } from './authentication/Login'
import { Register } from './authentication/Register'
import {
  ProtectedRoute,
  ProtectedRouteBuyer,
  ProtectedRouteSeller,
  RedirectAccount,
  RedirectHome
} from './routes'

import { About } from './site-info/About'
import { TermsAndConditions } from './site-info/TermsAndConditions'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/reducers/rootReducer'
import instance from '../../../providers/axios.config'
import { setCountries } from '../../../redux/reducers/countriesReducer'
import { Downloads } from './account-details/sections/buyer/downloads/Downloads'
import { Home } from './home/Home'
import { Invoices } from './account-details/sections/buyer/invoices/Invoices'
import { LightboxList } from './account-details/sections/buyer/lightbox/LightboxList'
import { MySubscriptions } from './account-details/sections/buyer/subscriptions_credits/MySubscriptions'
import { AccountSettings } from './account-details/AccountSettings'
import { MyCredits } from './account-details/sections/buyer/subscriptions_credits/MyCredits'
import { PaymentMethod } from './account-details/sections/buyer/payment_method/PaymentMethod'
import { Upload } from './account-details/sections/seller/upload/Upload'
import { MyPublications } from './account-details/sections/seller/publications/MyPublications'
import { ImagesLicensingList } from './account-details/sections/seller/publications/ImagesLicensingList'
import { DeclinedImagesList } from './account-details/sections/seller/publications/DeclinedImagesList'
import { UnfinishedImagesList } from './account-details/sections/seller/publications/UnfinishedImagesList'
import Examination from './account-details/sections/seller/publications/Examination'
import { Releases } from './account-details/sections/seller/releases/Releases'
import { Earnings } from './account-details/sections/seller/earnings/Earnings'
import { Statistics } from './account-details/sections/seller/earnings/Statistics'
import Payment from './account-details/sections/seller/payment/Payment'
import { PaymentHistory } from './account-details/sections/seller/payment/PaymentHistory'
import TaxCenter from './account-details/sections/seller/payment/TaxCenter'
import PaymentRoot from './account-details/sections/seller/payment/PaymentRoot'
import { PublicationsRoot } from './account-details/sections/seller/publications/PublicationsRoot'
import EarningRoot from './account-details/sections/seller/earnings/EarningRoot'
import NotificationsMain from './notificationsMain'
import Thankyou from './account-details/sections/seller/thankyou'
import { AssetDetailsBuy } from './asset-details/AssestsDetailBuy'
import Support from './account-details/Support'
import { ResetPassword } from './reset-password'

export const Content = () => {
  const dispatch = useDispatch()
  const countries = useSelector((state: RootState) => state.countries.countries)

  const getAllCountries = async () => {
    try {
      const response = await instance.get(`regions`)
      dispatch(setCountries(response.data))
    } catch (e) {

    }
  }
  useEffect(() => {

    if(!countries?.length) {

      getAllCountries()
    }
  }, [])
  return (
    <Routes>
      <Route path={NavigationEnum.HOME} element={<Home />} />

      <Route path={NavigationEnum.ASSET_DETAILS} element={<AssetDetails />} />
      <Route path={NavigationEnum.ASSET_DETAILS_BUY} element={<AssetDetailsBuy />} />
      <Route path={NavigationEnum.ASSETS} element={<Assets />} />
      <Route path={NavigationEnum.RESETPASSWORD} element={<ResetPassword />} />


      <Route path={NavigationEnum.ABOUT} element={<About />} />
      <Route path={NavigationEnum.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
      <Route path={ROUTES.NOTIFICATIONS} element={<ProtectedRoute element={<NotificationsMain />} />} />

      <Route path={NavigationEnum.PLANS_PRICES} element={<PlansPrices />} />
      <Route path={NavigationEnum.LOGIN} element={<Login />} />
      <Route
        path={NavigationEnum.REGISTER}
        element={<Register />}
      />
      <Route path="*" element={<Home />} />
      <Route
        path={NavigationEnum.FORGOT_PASSWORD}
        element={<ForgotPassword />}
      />
      <Route
        path={ROUTES.ACCOUNT}
        element={<RedirectAccount />}
      >
        <Route
          path={ROUTES.SUPPORT}
          element={<ProtectedRoute element={<Support />} />}
        />
        <Route
          path=""
          element={<RedirectHome />}
        />
        <Route
          path={ROUTES.PAYMENT}
          element={<ProtectedRouteBuyer element={<PaymentMethod />} />}
        />
        <Route
          path={ROUTES.DOWNLOADS}
          element={<ProtectedRouteBuyer element={<Downloads />} />}
        />
        <Route
          path={ROUTES.INVOICES}
          element={<ProtectedRouteBuyer element={<Invoices />} />}
        />
        <Route
          path={ROUTES.LIGHTBOX}
          element={<ProtectedRouteBuyer element={<LightboxList />} />}
        />
        <Route
          path={ROUTES.SUBSCRIPTIONS}
          element={<ProtectedRouteBuyer element={<MySubscriptions />} />}
        />
        <Route
          path={ROUTES.CREDITS}
          element={<ProtectedRouteBuyer element={<MyCredits />} />}
        />
        <Route
          path={ROUTES.SETTINGS}
          element={<ProtectedRoute element={<AccountSettings />} />}
        />
        <Route
          path={ROUTES.UPLOAD}
          element={<ProtectedRouteSeller path={ROUTES.UPLOAD} element={<Upload />} />}
        />
        <Route
          path={ROUTES.EARNINGS}
          element={<ProtectedRouteSeller element={<EarningRoot />} />}
        >
          <Route
            path=""
            element={<ProtectedRouteSeller element={<Earnings />} />}
          />
          <Route
            path={ROUTES.STATISTICS}
            element={<ProtectedRouteSeller element={<Statistics />} />}
          />
        </Route>
        <Route
          path={ROUTES.PUBLICATIONS}
          element={<ProtectedRouteSeller element={<PublicationsRoot />} />}
        >
          <Route
            path=""
            element={<ProtectedRouteSeller element={<MyPublications />} />}
          />
          <Route
            path={ROUTES.LICENSING}
            element={<ProtectedRouteSeller element={<ImagesLicensingList />} />}
          />
          <Route
            path={ROUTES.DECLINED}
            element={<ProtectedRouteSeller element={<DeclinedImagesList />} />}
          />
          <Route
            path={ROUTES.UNFINISHED}
            element={<ProtectedRouteSeller element={<UnfinishedImagesList />} />}
          />
          <Route
            path={ROUTES.EXAMINATION}
            element={<ProtectedRouteSeller element={<Examination />} />}
          />
        </Route>
        <Route
          path={ROUTES.RELEASES}
          element={<ProtectedRouteSeller element={<Releases />} />}
        />
        <Route
          path={ROUTES.MYPAYMENT}
          element={<ProtectedRouteSeller element={<PaymentRoot />} />}
        >
          <Route
            path=""
            element={<ProtectedRouteSeller element={<Payment />} />}
          />
          <Route
            path={ROUTES.HISTORY}
            element={<ProtectedRouteSeller element={<PaymentHistory />} />}
          />
          <Route
            path={ROUTES.TAXCENTER}
            element={<ProtectedRouteSeller element={<TaxCenter />} />}
          />
        </Route>
      </Route>
      <Route
        path={ROUTES.CONFIRM_PAYOUT}
        element={<ProtectedRouteSeller element={<Thankyou />} />}
      />
    </Routes>
  )
}
