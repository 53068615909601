import axios from 'axios';
import styles from './ModelReleaseList.module.scss'
import { Box, Button, IconButton } from '@mui/material'
import Notiflix from 'notiflix';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
  releases: any;
  canDeleteRelease?: boolean;
  onDelete?: (release: any) => void;
}

export const ModelReleaseList = ({releases, canDeleteRelease = false, onDelete}: IProps) => {

  const getFileName = (release: any) => {
    return release.filename.split('.')[0];
  };

  const handleDownload = async (linkUrl: string, filename: string) => {
    Notiflix.Loading.standard();
    axios({
      url: linkUrl,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      },
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      Notiflix.Loading.remove();
    }).catch((e) => {
      Notiflix.Loading.remove();
    });

  }
  return (
    <div className={styles.modelReleaseList}>
      {(releases || []).map((release: any) => (
        <Box
          className={styles.releaseContainer}
          sx={
            canDeleteRelease 
            ? {border: '1px solid #c7cdd4', borderRadius: 2, padding: '3px 5px'} 
            : {}
          }
        >
          <Button className={styles.modelReleaseItem} key={release?.id} variant="contained" onClick={() => handleDownload(release?.link, release.filename)}>
            {`${getFileName(release)}`}
          </Button>
          {canDeleteRelease && (
            <IconButton className={styles.deleteReleaseButton} onClick={() => typeof onDelete !== 'undefined' ? onDelete(release) : {}}>
              <DeleteIcon fontSize={'medium'} />
            </IconButton>
          )}
        </Box>
      ))}
    </div>
  )
}
