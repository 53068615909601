import { Alert, Box, Button, Chip, Divider } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Shared components
import { CustomTextfield } from '../../../../../../../shared-components/textfield/CustomTextField'

// Childs
import { UploaderContainerStep4 } from '../UploadContainerStep4'
import { ESelectedReleaseType } from '../ImageFieldArray'

// SCSS
import styles from './ReleasesTypes.module.scss'

// Redux
import { RootState } from '../../../../../../../../redux/reducers/rootReducer'

// Utils
import { MODEL_RELEASE_TYPE } from '../../../../../../../../utils/constants'

interface IProps {
  image: any
  control?: any,
  errors?: any,
  removeForm?: (formType: keyof typeof ESelectedReleaseType) => void,
  watch?: any,
  setValue?: any,
  getValues?: any,
}

export const Model = (props: IProps) => {
  const {
    image,
    control,
    removeForm,
    errors,
    watch,
    setValue,
    getValues,
  } = props
  const { t } = useTranslation()
  const accountInfo = useSelector((state: RootState) => state.user.usersData)

  const currentRelease = getValues('image');
  const dataFile = watch(`image.${MODEL_RELEASE_TYPE}.file`);
  const dataFileModelFile = watch(`image.${MODEL_RELEASE_TYPE}.model.model_path`)
  const dataFileModelPhotographer = watch(`image.${MODEL_RELEASE_TYPE}.photographer.photographer_signature`)
  const dataFileModelWithness = watch(`image.${MODEL_RELEASE_TYPE}.witness.witness_signature`)
  return (
    <Box display={'flex'} flexDirection='column'>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} flexWrap={'wrap'} gap={5}>
        <Controller
          name={`image.${MODEL_RELEASE_TYPE}.file`}
          control={control}
          render={({ field: { ref, ...newField } }) =>
            <UploaderContainerStep4
              allowedExtensions={['JPG', 'PDF']}
              allowedTypes={['application/pdf', 'image/jpeg']}
              title={''}
              value={dataFile}
              description={t('drop_pdf_jpg', { extensions: 'JPG, PDF' })}
              handleAddedFile={(file: File | null) => {
                if(file) {
                  setValue(`image.${MODEL_RELEASE_TYPE}.model`, {
                    model_name: '',
                    model_address: '',
                    model_date_signed: '',
                    model_dob: '',
                    model_email: '',
                    model_phone: '',
                    model_signature: undefined,
                    model_path: undefined,
                  })
                  setValue(`image.${MODEL_RELEASE_TYPE}.witness`, {
                    witness_name: '',
                    witness_date_signed: '',
                    witness_signature: undefined,
                  })
                }
                newField.onChange({
                  target: {
                    value: file,
                    name: `image.${MODEL_RELEASE_TYPE}.file`
                  }
                })
                // setValue(`image.${MODEL_RELEASE_TYPE}.file`, file)
              }}
          />}
        />
        <Alert
          sx={{ justifyContent: 'center', alignItems: 'flex-start', fontSize: 12 }}
          severity='info'
        >
          {t('accepted_digital_releases')}
        </Alert>
      </Box>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={1} flexWrap={'wrap'}>
        {dataFile && (
          <>
            <Box sx={{width: 250}}>
              <Controller
                name={`image.${MODEL_RELEASE_TYPE}.model.model_name`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    error={errors?.model?.model_name}
                    helperText={errors?.model?.model_name ? t(errors?.model?.model_name?.message) : ''}
                    variant='outlined'
                    label={t('Full Name')}
                    {...newField}
                  />
                }
              />
            </Box>
            <Button
              className={styles.button}
              type='submit'
            >
              {t('Save')}
            </Button>
            <Button
              onClick={() => {
                if (typeof removeForm !== 'undefined') {
                  removeForm(MODEL_RELEASE_TYPE);
                }
              }}
              className={styles.deleteModelButton}
            >
              {t('Delete release')}
            </Button>
          </>
        )}
      </Box>
      {!dataFile && (
        <>
          <Divider
            sx={{
              borderColor: 'grey.500',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Chip label={t('OR')}></Chip>
          </Divider>
          <Box sx={{alignItems: 'right'}}>
          </Box>
          <div style={{ position: 'relative' }} className={styles.modelPdf}>

            <div className={styles.termsAndConditions}>
              {t('Model_release_terms')}
            </div>
            <div>
              <div className={styles.contentFileUpload}>
                <div>
                  <img src={image.thumbnail} alt='img' className={styles.image} />
                  <br />
                  <span className={styles.titleText}>{t('Name')}:</span>{' '}
                  <span>{image.originalFilename}</span>
                </div>
                <div>
                  <span className={styles.titleText}>{t('Model file')}</span>
                  <Controller
                    name={`image.${MODEL_RELEASE_TYPE}.model.model_path`}
                    control={control}
                    render={({ field: { ref, ...newField } }) =>
                      <UploaderContainerStep4
                        title={''}
                        error={!!errors?.model?.model_path}
                        description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                        value={dataFileModelFile}
                        handleAddedFile={(file: File | null) => {
                          newField.onChange({
                            target: {
                              value: file,
                              name: `image.${MODEL_RELEASE_TYPE}.model.model_path`
                            }
                          })
                        }}
                      />}
                  />
                  <div
                    className={styles.errorText}>{errors?.model?.model_path ? t(errors?.model?.model_path?.message) : ''}</div>
                </div>
              </div>
              <br />
              <p className={styles.titleText}>Model Information: </p>
              <Controller
                name={`image.${MODEL_RELEASE_TYPE}.model.model_name`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield
                    className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    error={errors?.model?.model_name}
                    helperText={errors?.model?.model_name ? t(errors?.model?.model_name?.message) : ''}
                    variant='outlined'
                    label={t('Full Name')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${MODEL_RELEASE_TYPE}.model.model_dob`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className={styles.textfield}
                      label={t('Date Birth')}
                      format='dd-MM-yyyy'
                      disableFuture
                      {...newField}
                      slots={{
                        textField: CustomTextfield
                      }}
                      slotProps={{
                        textField: { size: 'small', error: !!errors?.model?.model_dob }
                      }}
                    />
                  </LocalizationProvider>}
              />
              <span
                className={styles.errorText}>{errors?.model?.model_dob ? t(errors?.model?.model_dob?.message) : ''}</span>
              <Controller
                name={`image.${MODEL_RELEASE_TYPE}.model.model_address`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    label={t('Full Address')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${MODEL_RELEASE_TYPE}.model.model_phone`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield
                    className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    error={errors?.model?.model_phone}
                    helperText={errors?.model?.model_phone ? t(errors?.model?.model_phone?.message) : ''}
                    variant='outlined'
                    label={t('Phone')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${MODEL_RELEASE_TYPE}.model.model_email`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield
                    className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    error={errors?.model?.model_email}
                    helperText={errors?.model?.model_email ? t(errors?.model?.model_email?.message) : ''}
                    label={t('Email')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${MODEL_RELEASE_TYPE}.model.model_date_signed`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className={styles.textfield}
                      label={t('Date Signed')}
                      format='dd-MM-yyyy'
                      disableFuture
                      {...newField}
                      slots={{
                        textField: CustomTextfield
                      }}
                      slotProps={{
                        textField: { size: 'small', error: !!errors?.model?.model_date_signed }
                      }}
                    />
                  </LocalizationProvider>}
              />
              <span
                className={styles.errorText}>{errors?.model?.model_date_signed ? t(errors?.model?.model_date_signed?.message) : ''}</span>
              <div>
                <span className={styles.titleText}>{t('Signature')}</span>
                <Controller
                  name={`image.${MODEL_RELEASE_TYPE}.model.model_signature`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <UploaderContainerStep4
                      title={''}
                      error={!!errors?.model?.model_signature}
                      description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                      value={currentRelease?.model?.model_signature}
                      handleAddedFile={(file: File | null) => {
                        newField.onChange({
                          target: {
                            value: file,
                            name: `image.${MODEL_RELEASE_TYPE}.model.model_signature`
                          }
                        })
                      }}
                    />}
                />
                <span
                  className={styles.errorText}>{errors?.model?.model_signature ? t(errors?.model?.model_signature?.message) : ''}</span>
              </div>
              <br />
              <div className={styles.formContainer}>
                <div className={styles.titleText}>Author Information:</div>
                <Controller
                  name={`image.${MODEL_RELEASE_TYPE}.photographer.photographer_name`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield
                      className={styles.textfield}
                      size='small'
                      disabled
                      inputRef={ref}
                      variant='outlined'
                      label={t('Full Name')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${MODEL_RELEASE_TYPE}.photographer.photographer_address`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield
                      className={styles.textfield}
                      size='small'
                      inputRef={ref}
                      disabled={!!accountInfo?.addresses?.[0]?.address}
                      variant='outlined'
                      label={t('Full Address')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${MODEL_RELEASE_TYPE}.photographer.photographer_phone`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield
                      className={styles.textfield}
                      size='small'
                      disabled={!!accountInfo?.addresses?.[0]?.phone}
                      inputRef={ref}
                      variant='outlined'
                      label={t('Phone')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${MODEL_RELEASE_TYPE}.photographer.photographer_email`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield
                      className={styles.textfield}
                      size='small'
                      disabled
                      inputRef={ref}
                      variant='outlined'
                      label={t('Email')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${MODEL_RELEASE_TYPE}.photographer.photographer_date_signed`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        {...newField}
                        className={styles.textfield}
                        label={t('Date Signed')}
                        format='dd-MM-yyyy'
                        disableFuture
                        slots={{
                          textField: CustomTextfield
                        }}
                        slotProps={{
                          textField: { size: 'small', error: !!errors?.photographer?.photographer_date_signed }
                        }}
                      />
                  </LocalizationProvider>}
                />
                <span
                  className={styles.errorText}>{errors?.photographer?.photographer_date_signed ? t(errors?.photographer?.photographer_date_signed?.message) : ''}</span>
              </div>
              <div>
                <span className={styles.titleText}>{t('Signature')}</span>
                <Controller
                  name={`image.${MODEL_RELEASE_TYPE}.photographer.photographer_signature`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <UploaderContainerStep4
                      title={''}
                      error={!!errors?.photographer?.photographer_signature}
                      description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                      value={dataFileModelPhotographer}
                      handleAddedFile={(file: File | null) => {
                        newField.onChange({
                          target: {
                            value: file,
                          }
                        })
                      }}
                    />}
                />
                <span
                  className={styles.errorText}>{errors?.photographer?.photographer_signature ? t(errors?.photographer?.photographer_signature?.message) : ''}</span>
              </div>
              <br />
              <p className={styles.titleText}>{t('witness_information')} </p>
              <Controller
                name={`image.${MODEL_RELEASE_TYPE}.witness.witness_name`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield 
                    className={styles.textfield}
                    size='small'
                    error={errors?.witness?.witness_name}
                    helperText={errors?.witness?.witness_name ? t(errors?.witness?.witness_name?.message) : ''}
                    inputRef={ref}
                    variant='outlined'
                    label={t('Full Name')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${MODEL_RELEASE_TYPE}.witness.witness_date_signed`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      {...newField}
                      className={styles.textfield}
                      label={t('Date Signed')}
                      format='dd-MM-yyyy'
                      disableFuture
                      slots={{
                        textField: CustomTextfield
                      }}
                      slotProps={{
                        textField: { size: 'small', error: !!errors?.witness?.witness_date_signed }
                      }}
                    />
                  </LocalizationProvider>}
              />
              <span
                className={styles.errorText}>{errors?.witness?.witness_date_signed ? t(errors?.witness?.witness_date_signed?.message) : ''}</span>
              <div>
                <span className={styles.titleText}>{t('Signature')}</span>
                <Controller
                  name={`image.${MODEL_RELEASE_TYPE}.witness.witness_signature`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <UploaderContainerStep4
                      title={''}
                      error={!!errors?.witness?.witness_signature}
                      description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                      value={dataFileModelWithness}
                      handleAddedFile={(file: File | null) => {
                        newField.onChange({
                          target: {
                            value: file,
                            name: `image.${MODEL_RELEASE_TYPE}.witness.witness_signature`
                          }
                        })
                      }}
                    />}
                />
                <span
                  className={styles.errorText}>{errors?.witness?.witness_signature ? t(errors?.witness?.witness_signature?.message) : ''}</span>
              </div>
              <div className={styles.containerInlineButtons}>
                <Button
                  className={styles.button}
                  type='submit'
                >
                  {t('Save')}
                </Button>
                <Button
                  className={styles.deleteModelButton}
                  onClick={() => {
                    if (typeof removeForm !== 'undefined') {
                      removeForm(MODEL_RELEASE_TYPE);
                    }
                  }}
                >
                  {t('Delete release')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Box>
  )
}
