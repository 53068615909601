import { Alert, Box, Button, Chip, Divider } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import moment from 'moment'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Shared components
import { CustomTextfield } from '../../../../../../../shared-components/textfield/CustomTextField'

// Childs
import { UploaderContainerStep4 } from '../UploadContainerStep4'
import { ESelectedReleaseType } from '../ImageFieldArray'

// SCSS
import styles from './ReleasesTypes.module.scss'

// Redux
import { RootState } from '../../../../../../../../redux/reducers/rootReducer'

// Utils
import { MINOR_RELEASE_TYPE } from '../../../../../../../../utils/constants'

interface IProps {
  image: any
  control?: any,
  errors?: any,
  removeForm?: (formType: keyof typeof ESelectedReleaseType) => void,
  watch?: any,
  setValue?: any,
  getValues?: any
}

export const MinorForm = (props: IProps) => {
  const {
    image,
    errors,
    removeForm,
    control,
    watch,
    setValue,
    getValues,
  } = props
  const { t } = useTranslation()
  const accountInfo = useSelector((state: RootState) => state.user.usersData);

  const currentRelease = getValues('image');

  const dataFile = watch(`image.${MINOR_RELEASE_TYPE}.file`)
  const dataFileMinorFile = watch(`image.${MINOR_RELEASE_TYPE}.minor.minor_path`)
  return (
    <Box display={'flex'} flexDirection='column' alignItems={'flex-start'} justifyContent={'center'}>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={5}>
        <Controller
          name={`image.${MINOR_RELEASE_TYPE}.file`}
          control={control}
          render={({ field: { ref, ...newField } }) =>
            <UploaderContainerStep4
              allowedExtensions={['JPG', 'PDF']}
              allowedTypes={['application/pdf', 'image/jpeg']}
              title={''}
              description={t('drop_pdf_jpg', { extensions: 'JPG, PDF' })}
              value={dataFile}
              handleAddedFile={(file: File | null) => {
                if(file) {
                  setValue(`image.${MINOR_RELEASE_TYPE}.minor`, {
                    minor_name: '',
                    minor_path: undefined,
                    minor_dob: '',
                  })
                  setValue(`image.${MINOR_RELEASE_TYPE}.parent`, {
                    parent_name: '',
                    parent_address: '',
                    parent_phone: '',
                    parent_email: '',
                    parent_date_signed: '',
                    parent_signature: undefined,
                  })
                  setValue(`image.${MINOR_RELEASE_TYPE}.photographer`, {
                    photographer_address: `${accountInfo?.addresses?.[0]?.address || ''}`,
                    photographer_email: accountInfo?.email,
                    photographer_name: `${accountInfo?.firstName} ${accountInfo?.lastName}`,
                    photographer_phone:  `${accountInfo?.addresses?.[0]?.phone || ''}`,
                    photographer_date_signed: new Date(),
                    photographer_signature: undefined,
                  })
                  setValue(`image.${MINOR_RELEASE_TYPE}.witness`, {
                    witness_name: '',
                    witness_date_signed: '',
                    witness_signature: undefined,
                  })
                }
                setValue(`image.${MINOR_RELEASE_TYPE}.file`, file)
                newField.onChange({
                  target: {
                    value: file,
                    name: `image.${MINOR_RELEASE_TYPE}.file`
                  }
                })
              }}
            />}
        />
        <Alert
          sx={{ justifyContent: 'center', alignItems: 'flex-start', fontSize: 12 }}
          severity='info'
        >
          {t('accepted_digital_releases')}
        </Alert>
      </Box>
      {dataFile && (
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} flexWrap={'wrap'} gap={1}>
          <Box sx={{width: 250}}>
            <Controller
              name={`image.${MINOR_RELEASE_TYPE}.minor.minor_name`}
              control={control}
              render={({ field: { ref, ...newField } }) =>
                <CustomTextfield className={styles.textfield}
                  size='small'
                  inputRef={ref}
                  error={errors?.minor?.minor_name}
                  helperText={errors?.minor?.minor_name ? t(errors?.minor?.minor_name.message) : ''}
                  variant='outlined'
                  label={t('Full Name')}
                  {...newField}
                />
              }
            />
          </Box>
          <Button
            type='submit'
            // onClick={handleSendReleases}
            className={styles.button}
          >
            {t('Save')}
          </Button>
          <Button
            onClick={() => {
              if (typeof removeForm !== 'undefined') {
                removeForm(MINOR_RELEASE_TYPE);
              }
            }}
            className={styles.deleteModelButton}
          >
            {t('Delete release')}
          </Button>
        </Box>
      )}
      {!dataFile && (
        <>
          <Divider
            sx={{
              borderColor: 'grey.500',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Chip label={t('OR')}></Chip>
          </Divider>
          <div style={{ position: 'relative' }} className={styles.minorPdf}>
            <div className={styles.termsAndConditions}>
              {t('Minor_release_terms')}
            </div>

            <div>
              <div className={styles.contentFileUpload}>
                <div>
                  <img src={image.thumbnail} alt='img' className={styles.image} />
                  <br />
                  <span className={styles.titleText}>{t('Name')}:</span>{' '}
                  <span>{image.originalFilename}</span>
                </div>
                <div>
                  <Controller
                    name={`image.${MINOR_RELEASE_TYPE}.minor.minor_path`}
                    control={control}
                    render={({ field: { ref, ...newField } }) =>
                      <UploaderContainerStep4
                        title={''}
                        error={!!errors?.minor?.minor_path}
                        description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                        value={dataFileMinorFile}
                        handleAddedFile={(file: File | null) => {
                          newField.onChange({
                            target: {
                              value: file,
                              name: `image.${MINOR_RELEASE_TYPE}.minor.minor_path`
                            }
                          })
                        }}
                      />}
                  />
                  <div
                    className={styles.errorText}>{errors?.minor?.minor_path ? t(errors?.minor?.minor_path?.message) : ''}</div>
                </div>

              </div>
              <br />
              <br />
              <p className={styles.titleText}>Minor Information: </p>
              <Controller
                name={`image.${MINOR_RELEASE_TYPE}.minor.minor_name`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    error={errors?.minor?.minor_name}
                    helperText={errors?.minor?.minor_name ? t(errors?.minor?.minor_name?.message)  : ''}
                    inputRef={ref}
                    variant='outlined'
                    label={t('Full Name')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${MINOR_RELEASE_TYPE}.minor.minor_dob`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className={styles.textfield}
                      label={t('Birth Date')}
                      format='dd-MM-yyyy'
                      disableFuture
                      minDate={new Date(moment().subtract(18, 'years').format('DD-MM-YYYY'))}
                      {...newField}
                      slots={{
                        textField: CustomTextfield
                      }}
                      slotProps={{
                        textField: { size: 'small', error: !!errors?.minor?.minor_dob },
                      }}
                    />
                  </LocalizationProvider>}
              />
              <span
                className={styles.errorText}>{errors?.minor?.minor_dob ? t(errors?.minor?.minor_dob?.message) : ''}</span>
              <br />
              <br />
              <p className={styles.titleText}>Parent/Guardian Information: </p>
              <Controller
                name={`image.${MINOR_RELEASE_TYPE}.parent.parent_name`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield
                    className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    error={errors?.parent?.parent_name}
                    helperText={errors?.parent?.parent_name ? t(errors?.parent?.parent_name?.message) : ''}
                    variant='outlined'
                    label={t('Full Name')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${MINOR_RELEASE_TYPE}.parent.parent_address`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield
                    className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    error={errors?.parent?.parent_address}
                    helperText={errors?.parent?.parent_address ? t(errors?.parent?.parent_address?.message) : ''}
                    label={t('Full Address')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${MINOR_RELEASE_TYPE}.parent.parent_phone`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield
                    className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    error={errors?.parent?.parent_phone}
                    helperText={errors?.parent?.parent_phone ? t(errors?.parent?.parent_phone?.message) : ''}
                    label={t('Phone')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${MINOR_RELEASE_TYPE}.parent.parent_email`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield
                    className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    error={errors?.parent?.parent_email}
                    helperText={errors?.parent?.parent_email ? t(errors?.parent?.parent_email?.message) : ''}
                    label={t('Email')}
                    {...newField} 
                  />
                }
              />
              <Controller
                name={`image.${MINOR_RELEASE_TYPE}.parent.parent_date_signed`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className={styles.textfield}
                      label={t('Date Signed')}
                      format='dd-MM-yyyy'
                      disableFuture
                      {...newField}
                      slots={{
                        textField: CustomTextfield
                      }}
                      slotProps={{
                        textField: { size: 'small', error: !!errors?.parent?.parent_date_signed },
                      }}
                    />
                  </LocalizationProvider>}
              />
              <span
                className={styles.errorText}>{errors?.parent?.parent_date_signed ? t(errors?.parent?.parent_date_signed?.message) : ''}</span>
              <div>
                <span className={styles.titleText}>{t('Signature')}</span>
                <Controller
                  name={`image.${MINOR_RELEASE_TYPE}.parent.parent_signature`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <UploaderContainerStep4
                      title={''}
                      error={!!errors?.parent?.parent_signature}
                      description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                      value={currentRelease.minor?.file}
                      handleAddedFile={(file: File | null) => {
                        newField.onChange({
                          target: {
                            value: file,
                            name: `image.${MINOR_RELEASE_TYPE}.parent.parent_signature`
                          }
                        })
                      }}
                    />}
                />
                <span
                  className={styles.errorText}>{errors?.parent?.parent_signature ? t(errors?.parent?.parent_signature?.message) : ''}</span>
              </div>
              <br />
              <div className={styles.formContainer}>
                <div className={styles.titleText}>{t('author_information')}</div>
                <Controller
                  name={`image.${MINOR_RELEASE_TYPE}.photographer.photographer_name`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield
                      className={styles.textfield}
                      size='small'
                      disabled
                      inputRef={ref}
                      variant='outlined'
                      label={t('Full Name')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${MINOR_RELEASE_TYPE}.photographer.photographer_address`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield
                      className={styles.textfield}
                      size='small'
                      disabled={!!accountInfo?.addresses?.[0]?.address}
                      inputRef={ref}
                      variant='outlined'
                      label={t('Full Address')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${MINOR_RELEASE_TYPE}.photographer.photographer_phone`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield
                      className={styles.textfield}
                      size='small'
                      inputRef={ref}
                      disabled={!!accountInfo?.addresses?.[0]?.phone}
                      variant='outlined'
                      label={t('Phone')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${MINOR_RELEASE_TYPE}.photographer.photographer_email`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield
                      className={styles.textfield}
                      size='small'
                      disabled
                      inputRef={ref}
                      variant='outlined'
                      label={t('Email')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${MINOR_RELEASE_TYPE}.photographer.photographer_date_signed`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className={styles.textfield}
                        label={t('Date Signed')}
                        format='dd-MM-yyyy'
                        disableFuture
                        {...newField}
                        slots={{
                          textField: CustomTextfield
                        }}
                        slotProps={{
                          textField: { size: 'small', error: !!errors?.photographer?.photographer_date_signed }
                        }}
                      />
                    </LocalizationProvider>}
                />
                <span className={styles.errorText}>{errors?.photographer?.photographer_date_signed ? t(errors?.photographer?.photographer_date_signed?.message) : ''}</span>
                
                <div>
                  <span className={styles.titleText}>{t('Signature')}</span>
                  <Controller
                    name={`image.${MINOR_RELEASE_TYPE}.photographer.photographer_signature`}
                    control={control}
                    render={({ field: { ref, ...newField } }) =>
                      <UploaderContainerStep4
                        title={''}
                        error={!!errors?.photographer?.photographer_signature}
                        description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                        value={currentRelease.minor?.file}
                        handleAddedFile={(file: File | null) => {
                          newField.onChange({
                            target: {
                              value: file,
                              name: `image.${MINOR_RELEASE_TYPE}.photographer.photographer_signature`
                            }
                          })
                        }}
                      />}
                  />
                  <span
                    className={styles.errorText}>{errors?.photographer?.photographer_signature ? t(errors?.photographer?.photographer_signature?.message) : ''}</span>
                </div>
              </div>
              <br />
              <p className={styles.titleText}>Witness Information: </p>
              <Controller
                name={`image.${MINOR_RELEASE_TYPE}.witness.witness_name`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield
                    className={styles.textfield}
                    size='small'
                    error={errors?.witness?.witness_name}
                    helperText={errors?.witness?.witness_name ? t(errors?.witness?.witness_name?.message) : ''}
                    inputRef={ref}
                    variant='outlined'
                    label={t('Full Name')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${MINOR_RELEASE_TYPE}.witness.witness_date_signed`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className={styles.textfield}
                      label={t('Date Signed')}
                      format='dd-MM-yyyy'
                      disableFuture
                      {...newField}
                      slots={{
                        textField: CustomTextfield
                      }}
                      slotProps={{
                        textField: { size: 'small', error: !!errors?.witness?.witness_date_signed }
                      }}
                    />
                  </LocalizationProvider>}
              />
              <span
                className={styles.errorText}>{errors?.witness?.witness_date_signed ? t(errors?.witness?.witness_date_signed?.message) : ''}</span>
              <div>
                <span className={styles.titleText}>{t('Signature')}</span>
                <Controller
                  name={`image.${MINOR_RELEASE_TYPE}.witness.witness_signature`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <UploaderContainerStep4
                      title={''}
                      error={!!errors?.witness?.witness_signature}
                      description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                      value={currentRelease.minor?.file}
                      handleAddedFile={(file: File | null) => {
                        newField.onChange({
                          target: {
                            value: file,
                            name: `image.${MINOR_RELEASE_TYPE}.witness.witness_signature`
                          }
                        })
                      }}
                    />}
                />
                <span
                  className={styles.errorText}>{errors?.witness?.witness_signature ? t(errors?.witness?.witness_signature?.message) : ''}</span>
              </div>
              <div className={styles.containerInlineButtons}>
                <Button
                  className={styles.button}
                  type='submit'
                >
                  {t('Save')}
                </Button>
                <Button
                  className={styles.deleteModelButton}
                  onClick={() => {
                    if (typeof removeForm !== 'undefined') {
                      removeForm(MINOR_RELEASE_TYPE);
                    }
                  }}
                >
                  {t('Delete release')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Box>
  )
}
