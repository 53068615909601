import { Alert, Box, Button, Chip, Divider } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { Controller } from 'react-hook-form'

// Redux
import { RootState } from '../../../../../../../../redux/reducers/rootReducer'

// Shared components
import { CustomTextfield } from '../../../../../../../shared-components/textfield/CustomTextField'

// Childs
import { UploaderContainerStep4 } from '../UploadContainerStep4'
import { ESelectedReleaseType } from '../ImageFieldArray'

// SCSS
import styles from './ReleasesTypes.module.scss'

// Utils
import { PROPERTY_RELEASE_TYPE } from '../../../../../../../../utils/constants'

interface IProps {
  image: any
  control?: any,
  setValue?: any,
  getValues?: any,
  watch?: any,
  name?: string,
  errors?: any,
  removeForm: (formType: keyof typeof ESelectedReleaseType) => void;
}

export const PropertyForm = (props: IProps) => {
  const {
    image,
    control,
    setValue,
    getValues,
    watch,
    errors,
    removeForm,
  } = props
  const accountInfo = useSelector((state: RootState) => state.user.usersData)
  const currentRelease = getValues('image');
  const dataFile = watch(`image.${PROPERTY_RELEASE_TYPE}.file`)
  const dataFilePropertyPhotographer = watch(`image.${PROPERTY_RELEASE_TYPE}.author.author_signature`)
  const dataFilePropertyWitness = watch(`image.${PROPERTY_RELEASE_TYPE}.witness.witness_signature`)
  return (
    <Box display={'flex'} flexDirection='column' alignItems={'flex-start'} justifyContent={'center'} flexWrap={'wrap'}>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} flexWrap={'wrap'} gap={5}>
        <Controller
          name={`image.${PROPERTY_RELEASE_TYPE}.file`}
          control={control}
          render={({ field: { ref, ...newField } }) =>
            <UploaderContainerStep4
              allowedExtensions={['JPG', 'PDF']}
              allowedTypes={['application/pdf', 'image/jpeg']}
              title={''}
              description={t('drop_pdf_jpg', { extensions: 'JPG, PDF' })}
              value={dataFile}
              handleAddedFile={(file: File | null) => {
                if(file) {
                  setValue(`image.${PROPERTY_RELEASE_TYPE}.owner`, {
                    owner_name: '',
                    owner_address: '',
                    owner_date_signed: '',
                    owner_email: '',
                    owner_phone: '',
                    owner_signature: undefined,
                  })
                  setValue(`image.${PROPERTY_RELEASE_TYPE}.witness`, {
                    witness_name: `${accountInfo?.firstName} ${accountInfo?.lastName}`,
                    witness_date_signed: new Date(),
                    witness_signature: undefined,
                  })
                }
                newField.onChange({
                  target: {
                    value: file,
                    name: `image.${PROPERTY_RELEASE_TYPE}.file`
                  }
                })
                // newField.onChange({target: { value: file, name: `${fieldArrayName}.property[${index}].file`}})
              }}
            />}
        />
        <Alert
          sx={{ justifyContent: 'center', alignItems: 'flex-start', fontSize: 12 }}
          severity='info'
        >
          {t('accepted_digital_releases')}
        </Alert>
      </Box>
      {dataFile && (
        <div className={styles.containerInlineButtons}>
          <Button
            type='submit'
            className={styles.button}
          >
            {t('Save')}
          </Button>
          <Button
            onClick={() => {
              if (typeof removeForm !== 'undefined') {
                removeForm(PROPERTY_RELEASE_TYPE);
              }
            }}
            className={styles.deleteModelButton}
          >
            {t('Delete release')}
          </Button>
        </div>
      )}
      {!dataFile && (
        <>
          <Divider
            sx={{
              borderColor: 'grey.500',
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Chip label={t('OR')}></Chip>
          </Divider>
          <div style={{ position: 'relative' }} className={styles.propertyPdf}>
            <div className={styles.termsAndConditions}>
              {t('Property_release_terms')}
            </div>

            <div>
              <img src={image?.thumbnail} alt='img' className={styles.image} />
              <br />
              <span className={styles.titleText}>{t('Name')}:</span>{' '}
              <span>{image.originalFilename}</span>
              <br />
              <br />
              <div className={styles.titleText}>
                {t('Property Owner / Authorized Representative')}
              </div>
              <br />
              <Controller
                name={`image.${PROPERTY_RELEASE_TYPE}.owner.owner_name`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    error={errors?.owner?.owner_name}
                    helperText={errors?.owner?.owner_name ? t(errors?.owner?.owner_name?.message) : ''}
                    inputRef={ref}
                    variant='outlined'
                    label={t('Full Name')}
                    {...newField} 
                  />
                }
              />
              <Controller
                name={`image.${PROPERTY_RELEASE_TYPE}.owner.owner_address`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield
                    className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    error={errors?.owner?.owner_address}
                    helperText={errors?.owner?.owner_address ? t(errors?.owner?.owner_address?.message) : ''}
                    label={t('Full Address')}
                    {...newField} 
                  />
                }
              />
              <Controller
                name={`image.${PROPERTY_RELEASE_TYPE}.owner.owner_phone`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield
                    className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    variant='outlined'
                    error={errors?.owner?.owner_phone}
                    helperText={errors?.owner?.owner_phone ? t(errors?.owner?.owner_phone?.message) : ''}
                    label={t('Phone')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${PROPERTY_RELEASE_TYPE}.owner.owner_email`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    error={errors?.owner?.owner_email}
                    helperText={errors?.owner?.owner_email ? t(errors?.owner?.owner_email?.message) : ''}
                    variant='outlined'
                    label={t('Email')}
                    {...newField} 
                  />
                }
              />
              <Controller
                name={`image.${PROPERTY_RELEASE_TYPE}.owner.owner_date_signed`}
                control={control}
                render={({ field: { ref, ...newField } }) => 
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                  >
                    <DatePicker
                      className={styles.textfield}
                      label={t('Date Signed')}
                      format='dd-MM-yyyy'
                      disableFuture
                      {...newField}
                      slots={{
                        textField: CustomTextfield
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: !!errors?.owner?.owner_date_signed,
                          helperText: errors?.owner?.owner_date_signed ? t(errors?.owner?.owner_date_signed?.message) : ''
                        }
                      }}
                    />
                  </LocalizationProvider>
                }
              />
              <div>
                <span className={styles.titleText}>{t('Signature')}</span>
                <Controller
                  name={`image.${PROPERTY_RELEASE_TYPE}.owner.owner_signature`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <UploaderContainerStep4
                      title={''}
                      error={!!errors?.owner?.owner_signature}
                      description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                      value={currentRelease.image?.property.owner?.owner_signature}
                      handleAddedFile={(file: File | null) => {
                        newField.onChange({
                          target: {
                            value: file,
                            name: `image.${PROPERTY_RELEASE_TYPE}.owner.owner_signature`
                          }
                        })
                      }}
                    />}
                />
                <span
                  className={styles.errorText}>{errors?.owner?.owner_signature ? t(errors?.owner?.owner_signature?.message) : ''}</span>
              </div>
              <br />
              <div className={styles.formContainer}>
                <span className={styles.titleText}>{t('Author Information')}</span>
                <br/>
                <Controller
                  name={`image.${PROPERTY_RELEASE_TYPE}.author.author_name`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield className={styles.textfield}
                      size='small'
                      disabled
                      inputRef={ref}
                      variant='outlined'
                      label={t('Full Name')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${PROPERTY_RELEASE_TYPE}.author.author_address`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield className={styles.textfield}
                      size='small'
                      inputRef={ref}
                      disabled={!!accountInfo?.addresses?.[0]?.address}
                      variant='outlined'
                      label={t('Full Address')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${PROPERTY_RELEASE_TYPE}.author.author_phone`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield className={styles.textfield}
                      size='small'
                      disabled={!!accountInfo?.addresses?.[0]?.phone}
                      inputRef={ref}
                      variant='outlined'
                      label={t('Phone')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${PROPERTY_RELEASE_TYPE}.author.author_email`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <CustomTextfield className={styles.textfield}
                      size='small'
                      disabled
                      inputRef={ref}
                      variant='outlined'
                      label={t('Email')}
                      {...newField}
                    />
                  }
                />
                <Controller
                  name={`image.${PROPERTY_RELEASE_TYPE}.author.author_date_signed`}
                  control={control}
                  render={({ field: { ref, ...newField } }) => 
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        className={styles.textfield}
                        label={t('Date Signed')}
                        format='dd-MM-yyyy'
                        disableFuture
                        {...newField}
                        slots={{
                          textField: CustomTextfield
                        }}
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: !!errors?.author?.author_date_signed,
                            helperText: errors?.author?.author_date_signed ? t(errors?.author?.author_date_signed?.message) : ''
                          }
                        }}
                      />
                    </LocalizationProvider>
                  }
                />
              </div>
              <div>
                <span className={styles.titleText}>{t('Signature')}</span>
                <Controller
                  name={`image.${PROPERTY_RELEASE_TYPE}.author.author_signature`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <UploaderContainerStep4
                      title={''}
                      error={!!errors?.author?.author_signature}
                      description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                      value={dataFilePropertyPhotographer}
                      handleAddedFile={(file: File | null) => {
                        newField.onChange({
                          target: {
                            value: file,
                            name: `image.${PROPERTY_RELEASE_TYPE}.author.author_signature`
                          }
                        })
                      }}
                    />}
                />
                <span
                  className={styles.errorText}>{errors?.author?.author_signature ? t(errors?.author?.author_signature?.message) : ''}</span>
              </div>
              <br />
              <p className={styles.titleText}>{t('witness_information')} </p>
              <Controller
                name={`image.${PROPERTY_RELEASE_TYPE}.witness.witness_name`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <CustomTextfield className={styles.textfield}
                    size='small'
                    inputRef={ref}
                    error={errors?.witness?.witness_name}
                    helperText={errors?.witness?.witness_name ? t(errors?.witness?.witness_name?.message) : ''}
                    variant='outlined'
                    label={t('Full Name')}
                    {...newField}
                  />
                }
              />
              <Controller
                name={`image.${PROPERTY_RELEASE_TYPE}.witness.witness_date_signed`}
                control={control}
                render={({ field: { ref, ...newField } }) =>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      className={styles.textfield}
                      label={t('Date Signed')}
                      format='dd-MM-yyyy'
                      disableFuture
                      {...newField}
                      slots={{
                        textField: CustomTextfield
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: !!errors?.witness?.witness_date_signed,
                          helperText: errors?.witness?.witness_date_signed ? t(errors?.witness?.witness_date_signed?.message) : ''
                        }
                      }}
                    />
                  </LocalizationProvider>}
              />
              <div>
                <span className={styles.titleText}>{t('Signature')}</span>
                <Controller
                  name={`image.${PROPERTY_RELEASE_TYPE}.witness.witness_signature`}
                  control={control}
                  render={({ field: { ref, ...newField } }) =>
                    <UploaderContainerStep4
                      title={''}
                      error={!!errors?.witness?.witness_signature}
                      description={t('drop_pdf_jpg', { extensions: 'JPG' })}
                      value={dataFilePropertyWitness}
                      handleAddedFile={(file: File | null) => {
                        newField.onChange({
                          target: {
                            value: file,
                            name: `image.${PROPERTY_RELEASE_TYPE}.witness.witness_signature`
                          }
                        })
                      }}
                    />}
                />
                <span
                  className={styles.errorText}>{errors?.witness?.witness_signature ? t(errors?.witness?.witness_signature?.message) : ''}
                </span>
              </div>
              <div className={styles.containerInlineButtons}>
                <Button
                  className={styles.button}
                  onClick={() => {}}
                  type="submit"
                >
                  {t('Save')}
                </Button>
                <Button
                  className={styles.deleteModelButton}
                  onClick={() => {
                    if (typeof removeForm !== 'undefined') {
                      removeForm(PROPERTY_RELEASE_TYPE);
                    }
                  }}
                >
                  {t('Delete release')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Box>
  )
}
