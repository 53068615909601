import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next';


import styles from './ReleaseForm.module.scss'
import instance from '../../../../../../../providers/axios.config'
import Notiflix from 'notiflix'
import { typeImageForDownload } from '../../../../asset-details/AssetDetails'
import { BASE_URL } from '../../../../../../../utils/constants'

interface IProps {
  logo: any
  releaseFormType: string
  pdfReleaseFormType: string
  digitalReleaseFormType: string
  pathPdf?: string,
  pathDigital?: string,
  name?: string,
}

export const ReleaseForm = (props: IProps) => {
  const { logo, releaseFormType, pdfReleaseFormType, digitalReleaseFormType, pathDigital, pathPdf, name } =
    props

  const { t } = useTranslation()

  const handleDownload = async (path?: string) => {
    Notiflix.Loading.standard({ svgColor: '#c11b1e' })
    const urlData = `${BASE_URL}${path}`
    const res = await instance.get(`${urlData}`, { responseType: 'arraybuffer' }) as any
    let blob = new Blob([res], { type: `image/jpeg` }),
      url = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${name}.jpg`)
    document.body.appendChild(link)
    link.click()
    Notiflix.Loading.remove()
  }
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <img src={logo} alt='logo' className={styles.logo} />

        <span className={styles.title}>{releaseFormType}</span>
      </div>

      <div className={styles.bottom}>
        <div className={styles.textAndButtonContainer}>
          <div className={styles.italicText}>{pdfReleaseFormType}</div>
          <Button onClick={() => handleDownload(pathPdf)} className={styles.downloadButton}>{t('Download')}</Button>
        </div>

        {/*<div className={styles.textAndButtonContainer}>*/}
        {/*  <div className={styles.italicText}>{digitalReleaseFormType}</div>*/}
        {/*  <Button onClick={() => handleDownload(pathDigital)} className={styles.downloadButton}>{t('Download')}</Button>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}
