import { forwardRef, useRef, useState, useImperativeHandle } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Notiflix from 'notiflix'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useTranslation } from 'react-i18next'

// SCSS
import styles from './Step4.module.scss'

// Shared components
import { ModelReleaseList } from '../../../../../../shared-components/card/ModelReleaseList'
import { PublicationImageCard } from '../../../../../../shared-components/card/PublicationImageCard'
import { ConfirmDialog } from '../../../../../../shared-components/dialogs/ConfirmDialog'

// API
import { deleteReleaseAPI } from '../../../../../../../providers/apiCalls/seller/api'

// Child
import { Step4ImageElement } from './Step4ImageElement'

// Redux
import { setUploadSession } from '../../../../../../../redux/reducers/uploadSessionReducer'
import { RootState } from '../../../../../../../redux/reducers/rootReducer'

// Utils
import { setInitialValuesMinor, setInitialValuesModel, setInitialValuesProperty } from '../../../../../../../utils/uploadSchemaValidator'
import { MINOR_RELEASE_TYPE, MODEL_RELEASE_TYPE, PROPERTY_RELEASE_TYPE } from '../../../../../../../utils/constants'

export enum ESelectedReleaseType {
  'property',
  'model',
  'minor'
}

export const ImageFieldArray = forwardRef(({
  control,
  getValues,
  releases,
  setReleases,
  errors,
  setValue,
  watch,
  clearErrors,
  onSaveImageToLicensing
}: any, ref) => {
  const step4ImageElementRef: any = useRef(null);
  const [openDeleteReleasePopup, setOpenDeleteReleasePopup] = useState<{
    isOpened: boolean,
    release: any;
  }>(
  {
    isOpened: false,
    release: null
  });

  const [openChangeReleaseTypePopup, setOpenChangeReleaseTypePopup] = useState<{
    isOpened: boolean,
    releaseType: keyof typeof ESelectedReleaseType | null;
  }>(
  {
    isOpened: false,
    releaseType: null,
  });

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const imageField = getValues('image');

  const [selectedReleaseType, setSelectedReleaseType] = useState<keyof typeof ESelectedReleaseType | null>(null);

  let uploadSession = useSelector(
    (state: any) => state.uploadSession.uploadSession
  )
  const accountInfo = useSelector((state: RootState) => state.user.usersData)

  const imageData = uploadSession?.images.find((item: any) => item?.imageId === imageField?.imageId)

  useImperativeHandle(ref, () => {
    return {
      getSelectedReleaseType: selectedReleaseType,
      cleanReleaseFormAfterSaving: () => {
        step4ImageElementRef.current?.setTabValue(selectedReleaseType || 'property');
        setSelectedReleaseType(null);
        setValue('image', {
          imageId: imageField.imageId
        });
      }
    };
  }, []);
  const handleDeleteRelease = async (release: any) => {
    const updatedImages = uploadSession.images.map((image: any) => {
      if (image.imageId === imageField?.imageId) {
        const updatedReleases = [...image.releases];
        const releaseIndex = image.releases.findIndex((item: any) => item.id === release.id);
        if (releaseIndex !== -1) {
          updatedReleases.splice(releaseIndex, 1);
        }
        return {
          ...image,
          releases: updatedReleases
        };
      }
      return image;
    });
    Notiflix.Loading.standard({ svgColor: '#c11b1e' });
    const resp = await deleteReleaseAPI(release.id);
    if (resp.status === 'success') {
      Notiflix.Notify.success(resp.message);
      setOpenDeleteReleasePopup({ isOpened: false, release: null });
      dispatch(setUploadSession({
        ...uploadSession,
        images: updatedImages
      }));
    } else {
      Notiflix.Notify.failure(resp.message);
    }
    Notiflix.Loading.remove();
  };
  return(
    <div>
      <div className={styles.imageAndUpload}>
        <PublicationImageCard
          image={imageData?.medium || ''}
          imageId={imageData?.imageId || ''}
          name={imageData?.originalFilename || ''}
          canBeDeleted={false}
        />
        <div className={styles.imageUploadReleasesList}>
          <FormControl className={styles.containerFormControl} size="small">
            <InputLabel id="demo-simple-select-label">{t('select_release_type')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedReleaseType || ''}
              label={t('select_release_type')}
              onChange={(ev: any) => {
                if (selectedReleaseType) {
                  setOpenChangeReleaseTypePopup({
                    isOpened: true,
                    releaseType: ev.target.value,
                  });
                } else {
                  clearErrors();
                  setSelectedReleaseType(ev.target.value);
                  if (ev.target.value) {
                    let formObject = {};
                    switch (ev.target.value) {
                      case PROPERTY_RELEASE_TYPE:
                        formObject = setInitialValuesProperty(accountInfo);
                        break;
                      case MODEL_RELEASE_TYPE:
                        formObject = setInitialValuesModel(accountInfo);
                        break;
                      case MINOR_RELEASE_TYPE:
                        formObject = setInitialValuesMinor(accountInfo);
                        break;
                    }
                    setValue('image', {
                      name: imageField.name,
                      imageId: imageField.imageId,
                      [ev.target.value]: formObject
                    });
                  }
                }
                step4ImageElementRef.current?.setTabValue(ev.target.value || 'property');
              }}
            >
              <MenuItem disableTouchRipple value={''} >{t('select_release_type')}</MenuItem>
              <MenuItem value={PROPERTY_RELEASE_TYPE}>{t('Property Release')}</MenuItem>
              <MenuItem value={MODEL_RELEASE_TYPE}>{t('Model Release')}</MenuItem>
              <MenuItem value={MINOR_RELEASE_TYPE}>{t('Minor Release')}</MenuItem>
            </Select>
          </FormControl>
          <ModelReleaseList
            releases={imageData?.releases || []}
            canDeleteRelease
            onDelete={(release) => setOpenDeleteReleasePopup({ isOpened: true, release })}
          />
        </div>
      </div>
      <Box className={styles.releasesContainer}>
        <Step4ImageElement
          watch={watch}
          setValue={setValue}
          getValues={getValues}
          errors={errors.image}
          control={control}
          setReleases={setReleases}
          releases={releases}
          selectedImage={imageData}
          description={'this is a description'}

          // Select realease type
          selectedReleaseType={selectedReleaseType}
          onRemoveReleaseForm={(formType: keyof typeof ESelectedReleaseType) => {
            setOpenChangeReleaseTypePopup({
              isOpened: true,
              releaseType: formType,
            })
            clearErrors();
          }}
          onSaveImageToLicensing={onSaveImageToLicensing}
          ref={step4ImageElementRef}
        />
      </Box>
      {openDeleteReleasePopup.isOpened && (
        <ConfirmDialog
          open={openDeleteReleasePopup.isOpened}
          handleOpen={() => setOpenDeleteReleasePopup({
            isOpened: !openDeleteReleasePopup.isOpened,
            release: openDeleteReleasePopup.isOpened ? null : openDeleteReleasePopup.release
          })}
          handleConfirm={() => {
            handleDeleteRelease(openDeleteReleasePopup.release)
          }}
          dialogTitle={t('Delete')}
          dialogMessage={t('Are you sure you want to delete release', {
            release: openDeleteReleasePopup.release.filename.split('.').shift(),
          })}
        />
      )}
      {openChangeReleaseTypePopup.isOpened && (
        <ConfirmDialog
          open={openChangeReleaseTypePopup.isOpened}
          handleOpen={() => setOpenChangeReleaseTypePopup({
            isOpened: !openChangeReleaseTypePopup.isOpened,
            releaseType: openChangeReleaseTypePopup.isOpened ? null : openChangeReleaseTypePopup.releaseType,
          })}
          handleConfirm={() => {
            let formObject = {};
            switch (openChangeReleaseTypePopup.releaseType) {
              case PROPERTY_RELEASE_TYPE:
                formObject = setInitialValuesProperty(accountInfo);
                break;
              case MODEL_RELEASE_TYPE:
                formObject = setInitialValuesModel(accountInfo);
                break;
              case MINOR_RELEASE_TYPE:
                formObject = setInitialValuesMinor(accountInfo);
                break;
            }
            if (openChangeReleaseTypePopup.releaseType === selectedReleaseType) {
              // this action comes from Step4ImageElement -> Delete Release button
              setSelectedReleaseType(null);
              setValue('image', {
                imageId: imageField.imageId
              });
            } else {
              setSelectedReleaseType(openChangeReleaseTypePopup.releaseType);
              setValue('image', {
                imageId: imageField.imageId,
                [openChangeReleaseTypePopup.releaseType || PROPERTY_RELEASE_TYPE]: formObject
              });
            }
            clearErrors();
            step4ImageElementRef.current?.setTabValue(openChangeReleaseTypePopup.releaseType || 'property');
            setOpenChangeReleaseTypePopup({
              isOpened: false,
              releaseType: null,
            });
          }}
          dialogTitle={t('Change release type')}
          dialogMessage={t('Are you sure you want to change release type')}
        />
      )}
    </div>
  )
});

export default ImageFieldArray;
