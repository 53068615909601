import { Container } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const TermsAndConditions = () => {
  const { t } = useTranslation()
  return (
    <Container maxWidth='lg'>
      <h1>{t('Terms and Conditions')}</h1>
      <div>{t('Terms_and_conditions_content')}</div>
    </Container>
  )
}
